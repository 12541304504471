import { get_all_records, delete_record, update_record, insert_record, get_dropdown_data, get_dropdown_data_value } from './connect';
/**
 * Textos del sistema utilizados en varios componentes
 */
export const sys_texts = {
  browse_nuevo: "Nuevo",
  form_guardar: "Guardar",
  form_cerrar: "Cerrar",
  search_placeholder: "Buscar",
  // Otros textos del sistema
};

/**
 * Textos específicos del CRUD para la entidad dominios
 */
export const crud_texts = {
  browse_titulo: "Procesos",
  form_titulo: "Proceso",
  modal_message: "¿Está seguro que desea eliminar este proceso?",
  modal_title: "Confirmación",
  modal_positive: "Eliminar",
  modal_negative: "Cancelar",
  // Otros textos específicos del CRUD
};

/**
 * Campos para el componente de navegación (browse)
 */
export const browse_json_fields = [
  { field: 'process_id', title: 'ID de Proceso', key: true, type: 'text', len: 20 },
  { field: 'process_dom_id', title: 'ID de Dominio', key: true, type: 'text', len: 20 },
  { field: 'process_name', title: 'Nombre', key: true, type: 'text', len: 255 },
  // Otros campos
];

/**
 * Campos para el formulario (form)
 */
export const form_json_fields = [
  { field: 'process_id', title: 'ID de Proceso', key: true, type: 'text', len: 20 },
  { field: 'process_dom_id', title: 'ID de Dominio', key: true, dom: true, type: 'text', len: 20 },
  { field: 'process_name', title: 'Nombre', type: 'text', len: 255 },
  { field: 'process_alert_at_finish', title: 'Alertar al Terminar', type: 'dropdown', len: 2 },
  { field: 'process_stop_number_faults', title: 'Detener en cantidad de fallas', type: 'number', len: 11 },
  { field: 'process_active', title: 'Activo', type: 'dropdown', len: 1 },
  // Otros campos específicos para el formulario
];

/**
 * Rutas de navegación
 */
export const routes = {
  edit:'/configuration/procesos/procesos/edit',
  new: '/configuration/procesos/procesos/new',
  list: '/configuration/procesos/procesos'
};

/**
 * Variables del entorno
 */
export const set_vars = () => {
  return {};
};

// Registro seleccionado
let selectedRecord = null;

export const setSelectedRecord = (record) => {
  selectedRecord = record;
};

export const getSelectedRecord = () => {
  return selectedRecord;
};

export { get_all_records, delete_record, update_record, insert_record, get_dropdown_data, get_dropdown_data_value };