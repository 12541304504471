import { get_all_records, delete_record, update_record, insert_record, get_dropdown_data, get_dropdown_data_value } from './connect';
/**
 * Textos del sistema utilizados en varios componentes
 */
export const sys_texts = {
  browse_nuevo: "Nuevo",
  form_guardar: "Guardar",
  form_cerrar: "Cerrar",
  search_placeholder: "Buscar",
  // Otros textos del sistema
};

/**
 * Textos específicos del CRUD para la entidad dominios
 */
export const crud_texts = {
  browse_titulo: "Steps Proceso",
  form_titulo: "Step Proceso",
  modal_message: "¿Está seguro que desea eliminar este step?",
  modal_title: "Confirmación",
  modal_positive: "Eliminar",
  modal_negative: "Cancelar",
  // Otros textos específicos del CRUD
};

/**
 * Campos para el componente de navegación (browse)
 */
export const browse_json_fields = [
  { field: 'process_id', title: 'ID de Proceso', key: true, type: 'text', len: 20 },
  { field: 'process_dom_id', title: 'ID de Dominio', key: true, type: 'text', len: 20 },
  { field: 'step_id', title: 'ID de Step', key: true, type: 'text', len: 20 },
  { field: 'rule_cruce_name', title: 'Cruce', type: 'text', len: 50 },
  { field: 'step_order', title: 'Orden de Step', type: 'number', len: 11 },
  { field: 'step_name', title: 'Nombre de Step', type: 'text', len: 255 },
  // Otros campos
];

/**
 * Campos para el formulario (form)
 */
export const form_json_fields = [
  { field: 'process_id', title: 'ID de Proceso', key: true, type: 'text', len: 20 },
  { field: 'process_dom_id', title: 'ID de Dominio', key: true, type: 'text', len: 20 },
  { field: 'step_id', title: 'ID de Step', key: true, type: 'text', len: 20 },
  { field: 'step_rule_id', title: 'Cruce', type: 'dropdown' },
  { field: 'step_order', title: 'Orden de Step', type: 'number', len: 11 },
  { field: 'step_name', title: 'Nombre de Step', type: 'text', len: 255 },
  { field: 'step_type', title: 'Tipo de Step', type: 'dropdown'},
  { field: 'step_cmd', title: 'Comando', type: 'dropdown' },
  // { field: 'step_common_argv', title: 'Argumentos Comunes', type: 'text' },
  // { field: 'step_additional_argv', title: 'Argumentos Adicionales', type: 'text' },
  { field: 'step_text', title: 'Descripción', type: 'text' },
  { field: 'step_active', title: 'Activo', type: 'dropdown', len: 1 },
  { field: 'step_run_mode', title: 'Modo de Ejecución', type: 'dropdown'},
  { field: 'step_alert_at_finish', title: 'Alertar al Terminar', type: 'dropdown', len: 1 },
  { field: 'step_stop_limit_faults', title: 'Detener en cantidad de fallas', type: 'number', len: 11 },
  // { field: 'step_progress_number_report', title: 'Reporte Progreso', type: 'number', len: 11 },
  // Otros campos específicos para el formulario
];

/**
 * Rutas de navegación
 */
export const routes = {
  edit: '/configuration/procesos/steps/edit',
  new: '/configuration/procesos/steps/new',
  list: '/configuration/procesos/steps'
};

/**
 * Variables del entorno
 */
export const set_vars = () => {
  return {};
};

// Registro seleccionado
let selectedRecord = null;

export const setSelectedRecord = (record) => {
  selectedRecord = record;
};

export const getSelectedRecord = () => {
  return selectedRecord;
};

export { get_all_records, delete_record, update_record, insert_record, get_dropdown_data, get_dropdown_data_value };