import { get_all_records, delete_record, update_record, insert_record, get_dropdown_ds, get_dropdown_trec, get_dropdown_fields, get_cruce_records } from './connect';
/**
 * Textos del sistema utilizados en varios componentes
 */
export const sys_texts = {
  browse_nuevo: "Nuevo",
  form_guardar: "Guardar",
  form_cerrar: "Cerrar",
  search_placeholder: "Buscar",
  // Otros textos del sistema
};

/**
 * Textos específicos del CRUD para la entidad dominios
 */
export const crud_texts = {
  browse_titulo: "Campos Cruce",
  form_titulo: "Campos Cruce",
  modal_message: "¿Está seguro que desea eliminar esta key?",
  modal_title: "Confirmación",
  modal_positive: "Eliminar",
  modal_negative: "Cancelar",
  // Otros textos específicos del CRUD
};

/**
 * Campos para el componente de navegación (browse)
 */
export const browse_json_fields = [
  // { field: 'rule_dom_id', title: 'ID de Dominio', key: true, type: 'text', len: 50 },
  // { field: 'rule_dse_id', title: 'ID de Data Source', type: 'text', len: 20 },
  // { field: 'rule_cruce_id', title: 'ID de Cruce', key: true, type: 'text', len: 50 },
  // { field: 'rule_side', title: 'Lado', key: true, type: 'text', len: 1 },
  // { field: 'rule_field_name', title: 'Nombre campo', key: true, type: 'text', len: 255 },
  // { field: 'rule_field_order', title: 'Orden', type: 'number', len: 11 },
  { field: 'rule_dom_id', title: 'ID de Dominio', key: true, type: 'text', len: 50 },
  { field: 'rule_cruce_id', title: 'ID de Cruce', key: true, type: 'text', len: 50 },
  { field: 'rule_cruce_name', title: 'Nombre Cruce', type: 'text', len: 255 },
  { field: 'rule_routine_nemo', title: 'Regla', type: 'text', len: 50 },
  // Otros campos
];

/**
 * Campos para el formulario (form)
 */
export const form_json_fields = [
  // { field: 'rule_dom_id', title: 'ID de Dominio', key: true, type: 'text', len: 50 },
  // { field: 'rule_dse_id', title: 'ID de Data Source', type: 'text', len: 20 },
  // { field: 'rule_cruce_id', title: 'ID de Cruce', key: true, type: 'text', len: 50 },
  // { field: 'rule_side', title: 'Lado', key: true, type: 'text', len: 1 },
  // { field: 'rule_field_name', title: 'Nombre campo', key: true, type: 'text', len: 255 },
  { field: 'rule_factor', title: 'Factor', type: 'number', len: 8 },
  { field: 'rule_operator', title: 'Operador', type: 'text', len: 2 },
  // { field: 'rule_field_order', title: 'Orden', type: 'number', len: 11 },
  // Otros campos específicos para el formulario
];

/**
 * Rutas de navegación
 */
export const routes = {
  edit: '/configuration/cruces/campos/edit',
  new: '/configuration/cruces/campos/new',
  list: '/configuration/cruces/campos'
};

/**
 * Variables del entorno
 */
export const set_vars = () => {
  return {
    set_browse_cruce: true,
    set_browse_cruce_campos: true,
  };
};

// Registro seleccionado
let selectedRecord = null;

export const setSelectedRecord = (record) => {
  selectedRecord = record;
};

export const getSelectedRecord = () => {
  return selectedRecord;
};

export { get_all_records, delete_record, update_record, insert_record, get_dropdown_ds, get_dropdown_trec, get_dropdown_fields, get_cruce_records };