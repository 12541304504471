const url_api_GWA = 'https://www.reclamospae.cl:5000/api/GWA';

// Obtener todos los registros
const get_all_records = async (id) => {
  try {
    const { dse_dom_id, dse_id } = JSON.parse(id);
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "ds_element_fmt", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"\\\\", \\\\"dse_id\\\\": \\\\"\\\\", \\\\"dse_rectype_id\\\\": \\\\"\\\\", \\\\"dse_field_number\\\\": \\\\"\\\\", \\\\"dse_field_name\\\\": \\\\"\\\\", \\\\"dse_field_type\\\\": \\\\"\\\\", \\\\"dse_field_len\\\\": \\\\"\\\\", \\\\"dse_field_dec\\\\": \\\\"\\\\", \\\\"dse_field_pic\\\\": \\\\"\\\\", \\\\"dse_field_nemo\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\"}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

// Eliminar un registro
const delete_record = async (id) => {
  try {
    const { dse_dom_id, dse_id, dse_rectype_id, dse_field_number } = JSON.parse(id);
    let objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "DELETE", "GC_TABLE": "ds_element_fmt", "GC_JSF": "{}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\", \\\\"dse_rectype_id\\\\": \\\\"' + dse_rectype_id + '\\\\", \\\\"dse_field_number\\\\": \\\\"' + dse_field_number + '\\\\"}"}';
    let jsonBody = JSON.stringify(objBody);
    let myUrl = url_api_GWA;
    let response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error eliminando el registro');
    }

    objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "ds_element_fmt", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"\\\\", \\\\"dse_id\\\\": \\\\"\\\\", \\\\"dse_rectype_id\\\\": \\\\"\\\\", \\\\"dse_field_number\\\\": \\\\"\\\\", \\\\"dse_field_name\\\\": \\\\"\\\\", \\\\"dse_field_type\\\\": \\\\"\\\\", \\\\"dse_field_len\\\\": \\\\"\\\\", \\\\"dse_field_dec\\\\": \\\\"\\\\", \\\\"dse_field_pic\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\", \\\\"dse_rectype_id\\\\": \\\\"' + dse_rectype_id + '\\\\"}"}';
    jsonBody = JSON.stringify(objBody);
    myUrl = url_api_GWA;
    response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    let result = await response.json();

    for (let i = 0; i < result.length; i++) {
      if (result[i].dse_field_number > dse_field_number) {
        objBody = {};
        objBody.sql_id = 'GWA_CRUD';
        objBody.params = '[]';
        objBody.sql_ve = '{"GC_CMD": "DELETE", "GC_TABLE": "ds_element_fmt", "GC_JSF": "{}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\", \\\\"dse_rectype_id\\\\": \\\\"' + dse_rectype_id + '\\\\", \\\\"dse_field_number\\\\": \\\\"' + result[i].dse_field_number + '\\\\"}"}';
        jsonBody = JSON.stringify(objBody);
        myUrl = url_api_GWA;
        response = await fetch(myUrl, {
          headers: {
            "Content-Type": "application/JSON"
          },
          method: "POST",
          body: jsonBody
        });
        if (!response.ok) {
          throw new Error('Error eliminando el registro');
        }

        objBody = {};
        objBody.sql_id = 'GWA_CRUD';
        objBody.params = '[]';
        objBody.sql_ve = '{"GC_CMD": "INSERT", "GC_TABLE": "ds_element_fmt", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"' + result[i].dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + result[i].dse_id + '\\\\", \\\\"dse_rectype_id\\\\": \\\\"' + result[i].dse_rectype_id + '\\\\", \\\\"dse_field_number\\\\": \\\\"' + (result[i].dse_field_number - 1) + '\\\\", \\\\"dse_field_name\\\\": \\\\"' + result[i].dse_field_name + '\\\\", \\\\"dse_field_type\\\\": \\\\"' + result[i].dse_field_type + '\\\\", \\\\"dse_field_len\\\\": \\\\"' + result[i].dse_field_len + '\\\\", \\\\"dse_field_dec\\\\": \\\\"' + result[i].dse_field_dec + '\\\\", \\\\"dse_field_pic\\\\": \\\\"' + result[i].dse_field_pic + '\\\\"}", "GC_JSK": "{}"}';
        jsonBody = JSON.stringify(objBody);
        myUrl = url_api_GWA;
        response = await fetch(myUrl, {
          headers: {
            "Content-Type": "application/JSON"
          },
          method: "POST",
          body: jsonBody
        });
        if (!response.ok) {
          throw new Error('Error actualizando el registro');
        }
      }
    }

    objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "DELETE", "GC_TABLE": "ds_element_load_table", "GC_JSF": "{}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\"}"}';
    jsonBody = JSON.stringify(objBody);
    myUrl = url_api_GWA;
    response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error eliminando el registro ds_element_load_table');
    }
    let dse_format_len = 0;
    let dataFormat = [];
    objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "ds_element_fmt", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"\\\\", \\\\"dse_id\\\\": \\\\"\\\\", \\\\"dse_rectype_id\\\\": \\\\"\\\\", \\\\"dse_field_number\\\\": \\\\"\\\\", \\\\"dse_field_name\\\\": \\\\"\\\\", \\\\"dse_field_type\\\\": \\\\"\\\\", \\\\"dse_field_len\\\\": \\\\"\\\\", \\\\"dse_field_dec\\\\": \\\\"\\\\", \\\\"dse_field_pic\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\"}"}';
    jsonBody = JSON.stringify(objBody);
    myUrl = url_api_GWA;
    response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (response.ok) {
      dataFormat = await response.json();
      if (dataFormat.length > 0) {
        let currentLength = 0;
        let maxLength = 0;
        let prevRectype = '';
        for (let j = 0; j < dataFormat.length; j++) {
          if (dataFormat[j].dse_rectype_id !== prevRectype && prevRectype !== '' && currentLength > maxLength) {
            maxLength = currentLength;
            currentLength = 0;
          }
          prevRectype = dataFormat[j].dse_rectype_id;
          currentLength += dataFormat[j].dse_field_len;
        }
        if (currentLength > maxLength) {
          maxLength = currentLength;
        }
        dse_format_len = maxLength;
      }
    }
    else {
      throw new Error('Error actualizando largo del archivo');
    }

    objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "INSERT", "GC_TABLE": "ds_element_load_table", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\", \\\\"dse_rectype_id\\\\": \\\\"FMT_LOAD\\\\", \\\\"dse_table_name_sufix\\\\": \\\\"tmp_' + dse_dom_id + '_' + dse_id + '_text\\\\", \\\\"dse_field_name\\\\": \\\\"f' + dse_format_len + '\\\\", \\\\"dse_field_type\\\\": \\\\"varchar\\\\", \\\\"dse_field_len\\\\": \\\\"' + dse_format_len + '\\\\"}", "GC_JSK": "{}"}';
    jsonBody = JSON.stringify(objBody);
    myUrl = url_api_GWA;
    response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error ingresando el registro ds_element_load_table');
    }

    return id;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

// Actualizar un registro
const update_record = async (data) => {
  try {
    let { dse_dom_id, dse_id, dse_rectype_id, dse_field_number, dse_field_nemo, dse_field_pic } = data;
    let { dse_field_type, dse_field_len, dse_field_dec } = descompactaCMP(dse_field_pic);
    let dse_field_name = dse_field_nemo;
    dse_field_name = dse_field_name.toLowerCase();
    dse_field_name = dse_field_name.replace('á', 'a');
    dse_field_name = dse_field_name.replace('é', 'e');
    dse_field_name = dse_field_name.replace('í', 'i');
    dse_field_name = dse_field_name.replace('ó', 'o');
    dse_field_name = dse_field_name.replace('ú', 'u');
    dse_field_name = dse_field_name.replace(/  +/g, '_');
    dse_field_name = dse_field_name.replace('-', '_');
    dse_field_name = dse_field_name.replace(/[^a-z0-9_]/, '');

    let objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "UPDATE", "GC_TABLE": "ds_element_fmt", "GC_JSF": "{\\\\"dse_field_name\\\\": \\\\"' + dse_field_name + '\\\\", \\\\"dse_field_type\\\\": \\\\"' + dse_field_type + '\\\\", \\\\"dse_field_len\\\\": \\\\"' + dse_field_len + '\\\\", \\\\"dse_field_dec\\\\": \\\\"' + dse_field_dec + '\\\\", \\\\"dse_field_pic\\\\": \\\\"' + dse_field_pic + '\\\\", \\\\"dse_field_nemo\\\\": \\\\"' + dse_field_nemo + '\\\\"}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\", \\\\"dse_rectype_id\\\\": \\\\"' + dse_rectype_id + '\\\\", \\\\"dse_field_number\\\\": \\\\"' + dse_field_number + '\\\\"}"}';
    let jsonBody = JSON.stringify(objBody);
    let myUrl = url_api_GWA;
    let response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error actualizando el registro');
    }
    const updatedData = await response.json();

    objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "DELETE", "GC_TABLE": "ds_element_load_table", "GC_JSF": "{}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\"}"}';
    jsonBody = JSON.stringify(objBody);
    myUrl = url_api_GWA;
    response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error eliminando el registro ds_element_load_table');
    }
    let dse_format_len = 0;
    let dataFormat = [];
    objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "ds_element_fmt", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"\\\\", \\\\"dse_id\\\\": \\\\"\\\\", \\\\"dse_rectype_id\\\\": \\\\"\\\\", \\\\"dse_field_number\\\\": \\\\"\\\\", \\\\"dse_field_name\\\\": \\\\"\\\\", \\\\"dse_field_type\\\\": \\\\"\\\\", \\\\"dse_field_len\\\\": \\\\"\\\\", \\\\"dse_field_dec\\\\": \\\\"\\\\", \\\\"dse_field_pic\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\"}"}';
    jsonBody = JSON.stringify(objBody);
    myUrl = url_api_GWA;
    response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (response.ok) {
      dataFormat = await response.json();
      if (dataFormat.length > 0) {
        let currentLength = 0;
        let maxLength = 0;
        let prevRectype = '';
        for (let j = 0; j < dataFormat.length; j++) {
          if (dataFormat[j].dse_rectype_id !== prevRectype && prevRectype !== '' && currentLength > maxLength) {
            maxLength = currentLength;
            currentLength = 0;
          }
          prevRectype = dataFormat[j].dse_rectype_id;
          currentLength += dataFormat[j].dse_field_len;
        }
        if (currentLength > maxLength) {
          maxLength = currentLength;
        }
        dse_format_len = maxLength;
      }
    }
    else {
      throw new Error('Error actualizando largo del archivo');
    }

    objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "INSERT", "GC_TABLE": "ds_element_load_table", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\", \\\\"dse_rectype_id\\\\": \\\\"FMT_LOAD\\\\", \\\\"dse_table_name_sufix\\\\": \\\\"tmp_' + dse_dom_id + '_' + dse_id + '_text\\\\", \\\\"dse_field_name\\\\": \\\\"f' + dse_format_len + '\\\\", \\\\"dse_field_type\\\\": \\\\"varchar\\\\", \\\\"dse_field_len\\\\": \\\\"' + dse_format_len + '\\\\"}", "GC_JSK": "{}"}';
    jsonBody = JSON.stringify(objBody);
    myUrl = url_api_GWA;
    response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error ingresando el registro ds_element_load_table');
    }

    return updatedData;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

// Ingresar un registro
const insert_record = async (data) => {
  try {
    let { dse_dom_id, dse_id, dse_rectype_id, dse_field_nemo, dse_field_pic } = data;
    let { dse_field_type, dse_field_len, dse_field_dec } = descompactaCMP(dse_field_pic);
    let dse_field_name = dse_field_nemo;
    dse_field_name = dse_field_name.toLowerCase();
    dse_field_name = dse_field_name.replace('á', 'a');
    dse_field_name = dse_field_name.replace('é', 'e');
    dse_field_name = dse_field_name.replace('í', 'i');
    dse_field_name = dse_field_name.replace('ó', 'o');
    dse_field_name = dse_field_name.replace('ú', 'u');
    dse_field_name = dse_field_name.replace(/  +/g, '_');
    dse_field_name = dse_field_name.replace('-', '_');
    dse_field_name = dse_field_name.replace(/[^a-z0-9_]/, '');

    let objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "ds_element_fmt", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"\\\\", \\\\"dse_id\\\\": \\\\"\\\\", \\\\"dse_rectype_id\\\\": \\\\"\\\\", \\\\"dse_field_number\\\\": \\\\"\\\\", \\\\"dse_field_name\\\\": \\\\"\\\\", \\\\"dse_field_type\\\\": \\\\"\\\\", \\\\"dse_field_len\\\\": \\\\"\\\\", \\\\"dse_field_dec\\\\": \\\\"\\\\", \\\\"dse_field_pic\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\", \\\\"dse_rectype_id\\\\": \\\\"' + dse_rectype_id + '\\\\"}"}';
    let jsonBody = JSON.stringify(objBody);
    let myUrl = url_api_GWA;
    let response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const result = await response.json();
    let dse_field_number = 0;
    for (let i = 0; i < result.length; i++) {
      if (result[i].dse_field_number > dse_field_number)
        dse_field_number = result[i].dse_field_number;
    }
    dse_field_number++;

    objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "INSERT", "GC_TABLE": "ds_element_fmt", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\", \\\\"dse_rectype_id\\\\": \\\\"' + dse_rectype_id + '\\\\", \\\\"dse_field_number\\\\": \\\\"' + dse_field_number + '\\\\", \\\\"dse_field_name\\\\": \\\\"' + dse_field_name + '\\\\", \\\\"dse_field_type\\\\": \\\\"' + dse_field_type + '\\\\", \\\\"dse_field_len\\\\": \\\\"' + dse_field_len + '\\\\", \\\\"dse_field_dec\\\\": \\\\"' + dse_field_dec + '\\\\", \\\\"dse_field_pic\\\\": \\\\"' + dse_field_pic + '\\\\", \\\\"dse_field_nemo\\\\": \\\\"' + dse_field_nemo + '\\\\"}", "GC_JSK": "{}"}';
    jsonBody = JSON.stringify(objBody);
    myUrl = url_api_GWA;
    response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error actualizando el registro');
    }

    objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "DELETE", "GC_TABLE": "ds_element_load_table", "GC_JSF": "{}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\"}"}';
    jsonBody = JSON.stringify(objBody);
    myUrl = url_api_GWA;
    response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error eliminando el registro ds_element_load_table');
    }
    let dse_format_len = 0;
    let dataFormat = [];
    objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "ds_element_fmt", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"\\\\", \\\\"dse_id\\\\": \\\\"\\\\", \\\\"dse_rectype_id\\\\": \\\\"\\\\", \\\\"dse_field_number\\\\": \\\\"\\\\", \\\\"dse_field_name\\\\": \\\\"\\\\", \\\\"dse_field_type\\\\": \\\\"\\\\", \\\\"dse_field_len\\\\": \\\\"\\\\", \\\\"dse_field_dec\\\\": \\\\"\\\\", \\\\"dse_field_pic\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\"}"}';
    jsonBody = JSON.stringify(objBody);
    myUrl = url_api_GWA;
    response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (response.ok) {
      dataFormat = await response.json();
      if (dataFormat.length > 0) {
        let currentLength = 0;
        let maxLength = 0;
        let prevRectype = '';
        for (let j = 0; j < dataFormat.length; j++) {
          if (dataFormat[j].dse_rectype_id !== prevRectype && prevRectype !== '' && currentLength > maxLength) {
            maxLength = currentLength;
            currentLength = 0;
          }
          prevRectype = dataFormat[j].dse_rectype_id;
          currentLength += dataFormat[j].dse_field_len;
        }
        if (currentLength > maxLength) {
          maxLength = currentLength;
        }
        dse_format_len = maxLength;
      }
    }
    else {
      throw new Error('Error actualizando largo del archivo');
    }

    objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "INSERT", "GC_TABLE": "ds_element_load_table", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\", \\\\"dse_rectype_id\\\\": \\\\"FMT_LOAD\\\\", \\\\"dse_table_name_sufix\\\\": \\\\"tmp_' + dse_dom_id + '_' + dse_id + '_text\\\\", \\\\"dse_field_name\\\\": \\\\"f' + dse_format_len + '\\\\", \\\\"dse_field_type\\\\": \\\\"varchar\\\\", \\\\"dse_field_len\\\\": \\\\"' + dse_format_len + '\\\\"}", "GC_JSK": "{}"}';
    jsonBody = JSON.stringify(objBody);
    myUrl = url_api_GWA;
    response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error ingresando el registro ds_element_load_table');
    }
    return [];
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

// Obtener los registros para los dropdown
const get_dropdown_data = async (field, dse_dom_id, idSelection) => {
  const { dse_id } = JSON.parse(idSelection);
  try {
    if (field === 'dse_rectype_id') {
      const objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "ds_element_rectypes", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"\\\\", \\\\"dse_id\\\\": \\\\"\\\\", \\\\"dse_rule_table_name\\\\": \\\\"\\\\", \\\\"dse_rectype_id\\\\": \\\\"\\\\", \\\\"dse_rectype_text\\\\": \\\\"\\\\", \\\\"dse_rectype_name\\\\": \\\\"\\\\", \\\\"dse_table_name_sufix\\\\": \\\\"\\\\", \\\\"dse_order\\\\": \\\\"\\\\", \\\\"dse_parse\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\"}"}';
      const jsonBody = JSON.stringify(objBody);
      const myUrl = url_api_GWA;
      const response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      for (let i = 0; i < data.length; i++) {
        data[i] = { ...data[i], dse_rectype_idvalue: data[i].dse_rectype_id };
      }
      return data;
    }
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

function descompactaCMP(valorPicture) {
  //dse_field_type , dse_field_len , dse_field_dec
  const salida = {
    dse_field_type: "",
    dse_field_len: 0,
    dse_field_dec: 0
  };
  //LO PRIMERO ES DETERMINA EL TIPO
  if (valorPicture[0] === "9" ||
    valorPicture[0] === "P") {
    salida.dse_field_type = "N";
  }
  if (valorPicture[0] === "X") {
    salida.dse_field_type = "T";
  }
  if (valorPicture[0] === "F") {
    salida.dse_field_type = "D";
  }
  if (valorPicture[0] === "R") {
    salida.dse_field_type = "R";
  }
  //AHORA SE DETERMINA EL LARGO Y DECIMALES SI ES QUE CORRESPONDE
  let largoTmp = "";
  let largoTmpNumero = 0;
  let activoTmp = false;
  let indice = 0;

  for (let i = 0; i < valorPicture.length; i++) {

    if (valorPicture[i] === ")") {
      indice = i + 1;
      largoTmpNumero = parseInt(largoTmp);
      break;
    }

    if (activoTmp) {
      largoTmp = largoTmp + valorPicture[i];
    }

    if (valorPicture[i] === "(") {
      // aca comienza un numero hasta el cierre de parentesis
      activoTmp = true;
      largoTmp = "";
    }
  }

  largoTmp = "";
  let largoTmpDecimal = 0;
  activoTmp = false;

  for (let i = indice; i < valorPicture.length; i++) {

    if (valorPicture[i] === ")") {
      indice = i + 1;
      largoTmpDecimal = parseInt(largoTmp);
      break;
    }

    if (activoTmp) {
      largoTmp = largoTmp + valorPicture[i];
    }

    if (valorPicture[i] === "(") {
      // aca comienza un numero hasta el cierre de parentesis
      activoTmp = true;
      largoTmp = "";
    }
  }

  if (salida.dse_field_type === "N") {
    salida.dse_field_len = largoTmpNumero + largoTmpDecimal;
    salida.dse_field_dec = largoTmpDecimal;
  } else {
    salida.dse_field_len = largoTmpNumero + largoTmpDecimal;
    salida.dse_field_dec = 0;
  }

  return salida;

};

const get_dropdown_data_value = async (field, value, dse_dom_id, idSelection) => {
  const { dse_id } = JSON.parse(idSelection);
  try {
    if (field === 'dse_rectype_id') {
      const objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "ds_element_rectypes", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"\\\\", \\\\"dse_id\\\\": \\\\"\\\\", \\\\"dse_rule_table_name\\\\": \\\\"\\\\", \\\\"dse_rectype_id\\\\": \\\\"\\\\", \\\\"dse_rectype_text\\\\": \\\\"\\\\", \\\\"dse_rectype_name\\\\": \\\\"\\\\", \\\\"dse_table_name_sufix\\\\": \\\\"\\\\", \\\\"dse_order\\\\": \\\\"\\\\", \\\\"dse_parse\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\", \\\\"dse_rectype_id\\\\": \\\\"' + value + '\\\\"}"}';
      const jsonBody = JSON.stringify(objBody);
      const myUrl = url_api_GWA;
      const response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      for (let i = 0; i < data.length; i++) {
        data[i] = { ...data[i], dse_rectype_idvalue: data[i].dse_rectype_id };
      }
      return data;
    }
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};  

export { get_all_records, delete_record, update_record, insert_record, get_dropdown_data, get_dropdown_data_value };