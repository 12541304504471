const url_api_GWA = 'https://www.reclamospae.cl:5000/api/GWA';

// Obtener todos los registros
const get_all_records = async (id) => {
  try {
    const { dom_id } = id;
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "mbr_process_definition", "GC_JSF": "{\\\\"process_id\\\\": \\\\"\\\\", \\\\"process_dom_id\\\\": \\\\"\\\\", \\\\"process_name\\\\": \\\\"\\\\", \\\\"process_alert_at_finish\\\\": \\\\"\\\\", \\\\"process_stop_number_faults\\\\": \\\\"\\\\", \\\\"process_active\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"process_dom_id\\\\": \\\\"' + dom_id + '\\\\"}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

// Eliminar un registro
const delete_record = async (id) => {
  try {
    const { process_id, process_dom_id, process_name } = JSON.parse(id);
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "DELETE", "GC_TABLE": "mbr_process_definition", "GC_JSF": "{}", "GC_JSK": "{\\\\"process_id\\\\": \\\\"' + process_id + '\\\\", \\\\"process_dom_id\\\\": \\\\"' + process_dom_id + '\\\\", \\\\"process_name\\\\": \\\\"' + process_name + '\\\\"}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error eliminando el registro');
    }
    return id;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

// Actualizar un registro
const update_record = async (data) => {
  try {
    const { process_id, process_dom_id, process_name, process_alert_at_finish, process_stop_number_faults, process_active } = data;
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "UPDATE", "GC_TABLE": "mbr_process_definition", "GC_JSF": "{\\\\"process_alert_at_finish\\\\": \\\\"' + process_alert_at_finish + '\\\\", \\\\"process_stop_number_faults\\\\": \\\\"' + process_stop_number_faults + '\\\\", \\\\"process_active\\\\": \\\\"' + process_active + '\\\\"}", "GC_JSK": "{\\\\"process_id\\\\": \\\\"' + process_id + '\\\\", \\\\"process_dom_id\\\\": \\\\"' + process_dom_id + '\\\\", \\\\"process_name\\\\": \\\\"' + process_name + '\\\\"}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error actualizando el registro');
    }
    const updatedData = await response.json();
    return updatedData;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

// Ingresar un registro
const insert_record = async (data) => {
  try {
    const { process_id, process_dom_id, process_name, process_alert_at_finish, process_stop_number_faults, process_active } = data;
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "INSERT", "GC_TABLE": "mbr_process_definition", "GC_JSF": "{\\\\"process_id\\\\": \\\\"' + process_id + '\\\\", \\\\"process_dom_id\\\\": \\\\"' + process_dom_id + '\\\\", \\\\"process_name\\\\": \\\\"' + process_name + '\\\\", \\\\"process_alert_at_finish\\\\": \\\\"' + process_alert_at_finish + '\\\\", \\\\"process_stop_number_faults\\\\": \\\\"' + process_stop_number_faults + '\\\\", \\\\"process_active\\\\": \\\\"' + process_active + '\\\\"}", "GC_JSK": "{}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error actualizando el registro');
    }
    const updatedData = await response.json();
    return updatedData;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

// Obtener los registros para los dropdown
const get_dropdown_data = async (field) => {
  try {
    if (field === 'process_alert_at_finish') {
      const objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE_ALL", "GC_TABLE": "mbr_sys_yesno", "GC_JSF": "{\\\\"yesno_code\\\\": \\\\"\\\\", \\\\"yesno_nemo\\\\": \\\\"\\\\"}", "GC_JSK": "{}"}';
      const jsonBody = JSON.stringify(objBody);
      const myUrl = url_api_GWA;
      const response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      for (let i = 0; i < data.length; i++) {
        data[i] = { ...data[i], process_alert_at_finish: data[i].yesno_nemo, process_alert_at_finishvalue: data[i].yesno_code };
      }
      return data;
    }
    else if (field === 'process_active') {
      const objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE_ALL", "GC_TABLE": "mbr_sys_yesno", "GC_JSF": "{\\\\"yesno_code\\\\": \\\\"\\\\", \\\\"yesno_nemo\\\\": \\\\"\\\\"}", "GC_JSK": "{}"}';
      const jsonBody = JSON.stringify(objBody);
      const myUrl = url_api_GWA;
      const response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      for (let i = 0; i < data.length; i++) {
        data[i] = { ...data[i], process_active: data[i].yesno_nemo, process_activevalue: data[i].yesno_code };
      }
      return data;
    }
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

const get_dropdown_data_value = async (field, value) => {
  try {
    if (field === 'process_alert_at_finish') {
      const objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "mbr_sys_yesno", "GC_JSF": "{\\\\"yesno_code\\\\": \\\\"\\\\", \\\\"yesno_nemo\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"yesno_code\\\\": \\\\"' + value + '\\\\"}"}';
      const jsonBody = JSON.stringify(objBody);
      const myUrl = url_api_GWA;
      const response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      for (let i = 0; i < data.length; i++) {
        data[i] = { ...data[i], process_alert_at_finish: data[i].yesno_nemo, process_alert_at_finishvalue: data[i].yesno_code };
      }
      return data;
    }
    else if (field === 'process_active') {
      const objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "mbr_sys_yesno", "GC_JSF": "{\\\\"yesno_code\\\\": \\\\"\\\\", \\\\"yesno_nemo\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"yesno_code\\\\": \\\\"' + value + '\\\\"}"}';
      const jsonBody = JSON.stringify(objBody);
      const myUrl = url_api_GWA;
      const response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      for (let i = 0; i < data.length; i++) {
        data[i] = { ...data[i], process_active: data[i].yesno_nemo, process_activevalue: data[i].yesno_code };
      }
      return data;
    }
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

export { get_all_records, delete_record, update_record, insert_record, get_dropdown_data, get_dropdown_data_value };