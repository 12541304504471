import React from 'react';
import { Routes, Route } from 'react-router-dom';
import createBrowseComponent from '../../../mbr_crud/Browse';
import createFormComponent from '../../../mbr_crud/Form';
import { ConnectionProvider } from '../../../mbr_crud/ConnectionContext';
import {
    browse_json_fields,
    form_json_fields,
    sys_texts,
    crud_texts,
    routes,
    get_all_records,
    delete_record,
    update_record,
    insert_record,
    get_dropdown_data,
    get_dropdown_data_value,
    set_vars
} from './crud_define';

const BrowseFuentesDSFormato = createBrowseComponent({
    browse_json_fields,
    form_json_fields,
    sys_texts,
    crud_texts,
    routes,
    get_all_records,
    delete_record
});

const FormFuentesDSFormato = createFormComponent({
    form_json_fields,
    sys_texts,
    crud_texts,
    routes,
    get_all_records,
    update_record,
    insert_record,
    get_dropdown_data,
    get_dropdown_data_value
});

const CrudRoutesConfFuentesDSFormato = () => (
    <ConnectionProvider get_all_records={get_all_records}
        delete_record={delete_record}
        update_record={update_record}
        insert_record={insert_record}
        get_dropdown_data={get_dropdown_data}
        get_dropdown_data_value={get_dropdown_data_value}
        set_vars={set_vars}
    >
        <Routes>
            <Route path="/" element={<BrowseFuentesDSFormato />} />
            <Route path="/new" element={<FormFuentesDSFormato />} />
            <Route path="/edit" element={<FormFuentesDSFormato />} />
        </Routes>
    </ConnectionProvider>
);

export default CrudRoutesConfFuentesDSFormato;