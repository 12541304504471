const url_api_GWA = 'https://www.reclamospae.cl:5000/api/GWA';

// Obtener todos los ID de cruce
const get_all_records = async (id) => {
  try {
    const { dom_id } = id;
    let objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "br_rule_master", "GC_JSF": "{\\\\"rule_dom_id\\\\": \\\\"\\\\", \\\\"rule_cruce_id\\\\": \\\\"\\\\", \\\\"rule_cruce_name\\\\": \\\\"\\\\", \\\\"rule_cruce_file_nok\\\\": \\\\"\\\\", \\\\"rule_cruce_file_max_nok\\\\": \\\\"\\\\", \\\\"rule_routine_id\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"rule_dom_id\\\\": \\\\"' + dom_id + '\\\\"}"}';
    let jsonBody = JSON.stringify(objBody);
    let myUrl = url_api_GWA;
    let response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();

    for (let i = 0; i < data.length; i++) {
      objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "br_rule_routines", "GC_JSF": "{\\\\"rule_routine_id\\\\": \\\\"\\\\", \\\\"rule_routine_name\\\\": \\\\"\\\\", \\\\"rule_routine_nemo\\\\": \\\\"\\\\", \\\\"rule_routine_type\\\\": \\\\"\\\\", \\\\"rule_routine_call\\\\": \\\\"\\\\", \\\\"rule_routine_common_argv\\\\": \\\\"\\\\", \\\\"rule_routine_additional_argv\\\\": \\\\"\\\\", \\\\"rule_routine_file_nok\\\\": \\\\"\\\\", \\\\"rule_routine_file_max_nok\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"rule_routine_id\\\\": \\\\"' + data[i].rule_routine_id + '\\\\"}"}';
      jsonBody = JSON.stringify(objBody);
      myUrl = url_api_GWA;
      response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const dataRule = await response.json();
      if (dataRule.length > 0)
        data[i] = { ...data[i], rule_routine_nemo: dataRule[0].rule_routine_nemo };
    }

    return data;
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

// Obtener todos los KEYS de cruce
const get_cruce_records = async (id) => {
  try {
    const { rule_dom_id, rule_cruce_id, rule_side } = id;
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "br_rule_keys", "GC_JSF": "{\\\\"rule_dom_id\\\\": \\\\"\\\\", \\\\"rule_dse_id\\\\": \\\\"\\\\", \\\\"rule_cruce_id\\\\": \\\\"\\\\", \\\\"rule_side\\\\": \\\\"\\\\", \\\\"rule_table_name\\\\": \\\\"\\\\", \\\\"rule_rectype_id\\\\": \\\\"\\\\", \\\\"rule_key_field_name\\\\": \\\\"\\\\", \\\\"rule_key_order\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"rule_dom_id\\\\": \\\\"' + rule_dom_id + '\\\\", \\\\"rule_cruce_id\\\\": \\\\"' + rule_cruce_id + '\\\\", \\\\"rule_side\\\\": \\\\"' + rule_side + '\\\\"}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

// Eliminar un registro
const delete_record = async (id) => {
  return [];
};

// Actualizar un registro
const update_record = async (id) => {
  try {
    const { rule_dom_id, rule_cruce_id, rule_dse_idA, rule_dse_idB, rule_table_nameA, rule_table_nameB, rule_rectype_idA, rule_rectype_idB, fieldsA, fieldsB } = id;
    let objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "DELETE", "GC_TABLE": "br_rule_keys", "GC_JSF": "{}", "GC_JSK": "{\\\\"rule_dom_id\\\\": \\\\"' + rule_dom_id + '\\\\", \\\\"rule_cruce_id\\\\": \\\\"' + rule_cruce_id + '\\\\"}"}';
    let jsonBody = JSON.stringify(objBody);
    let myUrl = url_api_GWA;
    await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });

    fieldsA?.forEach(async (item) => {
      objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      objBody.sql_ve = '{"GC_CMD": "INSERT", "GC_TABLE": "br_rule_keys", "GC_JSF": "{\\\\"rule_dom_id\\\\": \\\\"' + rule_dom_id + '\\\\", \\\\"rule_dse_id\\\\": \\\\"' + rule_dse_idA + '\\\\", \\\\"rule_cruce_id\\\\": \\\\"' + rule_cruce_id + '\\\\", \\\\"rule_side\\\\": \\\\"A\\\\", \\\\"rule_table_name\\\\": \\\\"' + rule_table_nameA + '\\\\", \\\\"rule_rectype_id\\\\": \\\\"' + rule_rectype_idA + '\\\\", \\\\"rule_key_field_name\\\\": \\\\"' + item.rule_key_field_name + '\\\\", \\\\"rule_key_order\\\\": \\\\"' + item.rule_key_order + '\\\\"}", "GC_JSK": "{}"}';
      jsonBody = JSON.stringify(objBody);
      myUrl = url_api_GWA;
      const response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Error actualizando el registro');
      }
    })

    fieldsB?.forEach(async (item) => {
      objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      objBody.sql_ve = '{"GC_CMD": "INSERT", "GC_TABLE": "br_rule_keys", "GC_JSF": "{\\\\"rule_dom_id\\\\": \\\\"' + rule_dom_id + '\\\\", \\\\"rule_dse_id\\\\": \\\\"' + rule_dse_idB + '\\\\", \\\\"rule_cruce_id\\\\": \\\\"' + rule_cruce_id + '\\\\", \\\\"rule_side\\\\": \\\\"B\\\\", \\\\"rule_table_name\\\\": \\\\"' + rule_table_nameB + '\\\\", \\\\"rule_rectype_id\\\\": \\\\"' + rule_rectype_idB + '\\\\", \\\\"rule_key_field_name\\\\": \\\\"' + item.rule_key_field_name + '\\\\", \\\\"rule_key_order\\\\": \\\\"' + item.rule_key_order + '\\\\"}", "GC_JSK": "{}"}';
      jsonBody = JSON.stringify(objBody);
      myUrl = url_api_GWA;
      const response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Error actualizando el registro');
      }
    })

    return [];
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

// Ingresar un registro
const insert_record = async (id) => {
  return [];
};

//Obtener todos los ID de Data Source
const get_dropdown_ds = async (id) => {
  try {
    const { dse_dom_id } = id;
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "ds_element_id", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"\\\\", \\\\"dse_id\\\\": \\\\"\\\\", \\\\"dse_name\\\\": \\\\"\\\\", \\\\"dse_template_name\\\\": \\\\"\\\\", \\\\"dse_desc\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\"}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

//Obtener todos los Tipo de registro de un ID de Data Source
const get_dropdown_trec = async (id) => {
  try {
    const { dse_dom_id, dse_id } = id;
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "ds_element_rectypes", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"\\\\", \\\\"dse_id\\\\": \\\\"\\\\", \\\\"dse_rule_table_name\\\\": \\\\"\\\\", \\\\"dse_rectype_id\\\\": \\\\"\\\\", \\\\"dse_rectype_text\\\\": \\\\"\\\\", \\\\"dse_rectype_name\\\\": \\\\"\\\\", \\\\"dse_table_name_sufix\\\\": \\\\"\\\\", \\\\"dse_order\\\\": \\\\"\\\\", \\\\"dse_parse\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\"}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

//Obtener todos los Cammpos del Formato para un Tipo de registro de un ID de Data Source
const get_dropdown_fields = async (id) => {
  try {
    const { dse_dom_id, dse_id, dse_rectype_id } = id;
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "ds_element_fmt", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"\\\\", \\\\"dse_id\\\\": \\\\"\\\\", \\\\"dse_rectype_id\\\\": \\\\"\\\\", \\\\"dse_field_number\\\\": \\\\"\\\\", \\\\"dse_field_name\\\\": \\\\"\\\\", \\\\"dse_field_type\\\\": \\\\"\\\\", \\\\"dse_field_len\\\\": \\\\"\\\\", \\\\"dse_field_dec\\\\": \\\\"\\\\", \\\\"dse_field_pic\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\", \\\\"dse_rectype_id\\\\": \\\\"' + dse_rectype_id + '\\\\"}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

export { get_all_records, delete_record, update_record, insert_record, get_dropdown_ds, get_dropdown_trec, get_dropdown_fields, get_cruce_records };