import { get_all_records, delete_record, update_record, insert_record } from './connect';
/**
 * Textos del sistema utilizados en varios componentes
 */
export const sys_texts = {
  form_guardar: "Cargar",
  form_cerrar: "Cerrar",
  search_placeholder: "Buscar",
  // Otros textos del sistema
};

/**
 * Textos específicos del CRUD para la entidad dominios
 */
export const crud_texts = {
  browse_titulo: "Seleccione Data Source",
  form_titulo: "Cargar Data Source",
  modal_message: "¿Confirma carga de este Data Source?",
  modal_title: "Confirmación",
  modal_positive: "Cargar",
  modal_negative: "Cancelar",
  // Otros textos específicos del CRUD
};

/**
 * Campos para el componente de navegación (browse)
 */
export const browse_json_fields = [
  { field: 'dse_dom_id', title: 'ID de Dominio', key: true, type: 'text', len: 20 },
  { field: 'dse_wks_id', title: 'ID de Workspace', key: true, type: 'text', len: 20 },
  { field: 'dse_id', title: 'ID de Data Source', key: true, type: 'text', len: 20 },
  { field: 'dse_name', title: 'Nombre', type: 'text', len: 20 },
  { field: 'wks_sys_status', title: 'Estado', type: 'text', len: 20 },
  { field: 'wks_sys_progress', title: 'Progreso', type: 'number', len: 3 },
  { field: 'wks_sys_records', title: 'Registros', type: 'text', len: 255 },
  // Otros campos
];

/**
 * Campos para el formulario (form)
 */
export const form_json_fields = [
  { field: 'dse_file', title: 'Seleccione archivo', type: 'file' },
  // Otros campos específicos para el formulario
];

/**
 * Rutas de navegación
 */
export const routes = {
  upload: '/operation/fuentesDS/upload/upload',
  list: '/operation/fuentesDS/upload',
};

/**
 * Variables del entorno
 */
export const set_vars = () => {
  return {
    set_browse_upload: true,
  };
};

// Registro seleccionado
let selectedRecord = null;

export const setSelectedRecord = (record) => {
  selectedRecord = record;
};

export const getSelectedRecord = () => {
  return selectedRecord;
};

export { get_all_records, delete_record, update_record, insert_record };