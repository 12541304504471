const url_api_GWA = 'https://www.reclamospae.cl:5000/api/GWA';
const url_api_process_exec_start = 'https://www.reclamospae.cl:5000/process_exec/start';

// Obtener todos los registros
const get_all_records = async (id) => {
  try {
    const { dom_id } = id;
    let objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "mbr_process_definition", "GC_JSF": "{\\\\"process_id\\\\": \\\\"\\\\", \\\\"process_dom_id\\\\": \\\\"\\\\", \\\\"process_name\\\\": \\\\"\\\\", \\\\"process_alert_at_finish\\\\": \\\\"\\\\", \\\\"process_stop_number_faults\\\\": \\\\"\\\\", \\\\"process_active\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"process_dom_id\\\\": \\\\"' + dom_id + '\\\\"}"}';
    let jsonBody = JSON.stringify(objBody);
    let myUrl = url_api_GWA;
    let response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const dataProcessDef = await response.json();

    let result = [];
    let dataSteps = [];

    for (let i = 0; i < dataProcessDef.length; i++) {
      objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "mbr_step_definition", "GC_JSF": "{\\\\"process_id\\\\": \\\\"\\\\", \\\\"process_dom_id\\\\": \\\\"\\\\", \\\\"step_id\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"process_id\\\\": \\\\"' + dataProcessDef[i].process_id + '\\\\", \\\\"process_dom_id\\\\": \\\\"' + dataProcessDef[i].process_dom_id + '\\\\"}"}';
      jsonBody = JSON.stringify(objBody);
      myUrl = url_api_GWA;
      response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (response.ok) {
        dataSteps = await response.json();
        if (dataSteps.length > 0) {
          result = [...result, { ...dataProcessDef[i] }];
        }
      }
    }
    return result;
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

// Eliminar un registro
const delete_record = async (id) => {
  return null;
};

// Actualizar un registro
const update_record = async (data) => {
  try {
    const { process_id, process_dom_id, process_wks_id } = data;
    const objBody = {};
    objBody.process_id = process_id;
    objBody.process_dom_id = process_dom_id;
    objBody.spacework = process_wks_id;
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_process_exec_start;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error);
    }
    const updatedData = await response.json();
    return updatedData;
  } catch (error) {
    console.error(error);
    return null;
  }
};

// Ingresar un registro
const insert_record = async (data) => {
  return null;
};

export { get_all_records, delete_record, update_record, insert_record };