const url_api_GWA = 'https://www.reclamospae.cl:5000/api/GWA';
const url_api_insert = 'https://www.reclamospae.cl:5000/step_definition/create';
const url_api_update = 'https://www.reclamospae.cl:5000/step_definition/update';

// Obtener todos los registros
const get_all_records = async (id) => {
  try {
    const { process_id, process_dom_id } = JSON.parse(id);
    let objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "mbr_step_definition", "GC_JSF": "{\\\\"process_id\\\\": \\\\"\\\\", \\\\"process_dom_id\\\\": \\\\"\\\\", \\\\"step_id\\\\": \\\\"\\\\", \\\\"step_rule_id\\\\": \\\\"\\\\", \\\\"step_order\\\\": \\\\"\\\\", \\\\"step_name\\\\": \\\\"\\\\", \\\\"step_type\\\\": \\\\"\\\\", \\\\"step_cmd\\\\": \\\\"\\\\", \\\\"step_common_argv\\\\": \\\\"\\\\", \\\\"step_additional_argv\\\\": \\\\"\\\\", \\\\"step_text\\\\": \\\\"\\\\", \\\\"step_active\\\\": \\\\"\\\\", \\\\"step_run_mode\\\\": \\\\"\\\\", \\\\"step_alert_at_finish\\\\": \\\\"\\\\", \\\\"step_stop_limit_faults\\\\": \\\\"\\\\", \\\\"step_progress_number_report\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"process_id\\\\": \\\\"' + process_id + '\\\\", \\\\"process_dom_id\\\\": \\\\"' + process_dom_id + '\\\\"}"}';
    let jsonBody = JSON.stringify(objBody);
    let myUrl = url_api_GWA;
    let response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();

    for (let i = 0; i < data.length; i++) {
      objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      // objBody.sql_ve = '{"GC_CMD": "RETRIEVE_ALL", "GC_TABLE": "br_rule_routines", "GC_JSF": "{\\\\"rule_routine_id\\\\": \\\\"\\\\", \\\\"rule_routine_name\\\\": \\\\"\\\\", \\\\"rule_routine_nemo\\\\": \\\\"\\\\", \\\\"rule_routine_type\\\\": \\\\"\\\\", \\\\"rule_routine_call\\\\": \\\\"\\\\", \\\\"rule_routine_common_argv\\\\": \\\\"\\\\", \\\\"rule_routine_additional_argv\\\\": \\\\"\\\\", \\\\"rule_routine_file_nok\\\\": \\\\"\\\\", \\\\"rule_routine_file_max_nok\\\\": \\\\"\\\\"}", "GC_JSK": "{}"}';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "br_rule_master", "GC_JSF": "{\\\\"rule_dom_id\\\\": \\\\"\\\\", \\\\"rule_cruce_id\\\\": \\\\"\\\\", \\\\"rule_cruce_name\\\\": \\\\"\\\\", \\\\"rule_cruce_file_nok\\\\": \\\\"\\\\", \\\\"rule_cruce_file_max_nok\\\\": \\\\"\\\\", \\\\"rule_routine_id\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"rule_dom_id\\\\": \\\\"' + data[i].process_dom_id + '\\\\", \\\\"rule_cruce_id\\\\": \\\\"' + data[i].step_rule_id + '\\\\"}"}';
      jsonBody = JSON.stringify(objBody);
      myUrl = url_api_GWA;
      response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const dataRule = await response.json();
      if (dataRule.length>0)
        data[i] = { ...data[i], rule_cruce_name: dataRule[0].rule_cruce_name };
    }
    return data;
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

// Eliminar un registro
const delete_record = async (id) => {
  try {
    const { process_id, process_dom_id, step_id } = JSON.parse(id);
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "DELETE", "GC_TABLE": "mbr_step_definition", "GC_JSF": "{}", "GC_JSK": "{\\\\"process_id\\\\": \\\\"' + process_id + '\\\\", \\\\"process_dom_id\\\\": \\\\"' + process_dom_id + '\\\\", \\\\"step_id\\\\": \\\\"' + step_id + '\\\\"}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error eliminando el registro');
    }
    return id;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

// Actualizar un registro
const update_record = async (id) => {
  try {
    const { process_id, process_dom_id, step_id, step_rule_id, step_order, step_name, step_type, step_cmd, step_text, step_active, step_run_mode, step_alert_at_finish, step_stop_limit_faults, step_progress_number_report } = id;

    let objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "br_rule_fields", "GC_JSF": "{\\\\"rule_dom_id\\\\": \\\\"\\\\", \\\\"rule_dse_id\\\\": \\\\"\\\\", \\\\"rule_cruce_id\\\\": \\\\"\\\\", \\\\"rule_side\\\\": \\\\"\\\\", \\\\"rule_field_name\\\\": \\\\"\\\\", \\\\"rule_rectype_id\\\\": \\\\"\\\\", \\\\"rule_factor\\\\": \\\\"\\\\", \\\\"rule_operator\\\\": \\\\"\\\\", \\\\"rule_field_order\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"rule_dom_id\\\\": \\\\"' + process_dom_id + '\\\\", \\\\"rule_cruce_id\\\\": \\\\"' + step_rule_id + '\\\\", \\\\"rule_side\\\\": \\\\"A\\\\"}"}';
    let jsonBody = JSON.stringify(objBody);
    let myUrl = url_api_GWA;
    let response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const dataA = await response.json();

    objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "br_rule_fields", "GC_JSF": "{\\\\"rule_dom_id\\\\": \\\\"\\\\", \\\\"rule_dse_id\\\\": \\\\"\\\\", \\\\"rule_cruce_id\\\\": \\\\"\\\\", \\\\"rule_side\\\\": \\\\"\\\\", \\\\"rule_field_name\\\\": \\\\"\\\\", \\\\"rule_rectype_id\\\\": \\\\"\\\\", \\\\"rule_factor\\\\": \\\\"\\\\", \\\\"rule_operator\\\\": \\\\"\\\\", \\\\"rule_field_order\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"rule_dom_id\\\\": \\\\"' + process_dom_id + '\\\\", \\\\"rule_cruce_id\\\\": \\\\"' + step_rule_id + '\\\\", \\\\"rule_side\\\\": \\\\"B\\\\"}"}';
    jsonBody = JSON.stringify(objBody);
    myUrl = url_api_GWA;
    response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const dataB = await response.json();

    // objBody = {};
    // const step_common_argv = "{ 'dom_id':'" + process_dom_id + "','rule_id':'" + step_rule_id +"','wks_id':'','elements': [ { 'wks_element':'" + dataA[0].rule_dse_id + "', 'wks_type':'DATA_SOURCE', 'wks_table_name':'', 'wks_side':'A', 'wks_rectype':'" + dataA[0].rule_rectype_id + "' }, {'wks_element':'" + dataB[0].rule_dse_id + "','wks_type':'DATA_SOURCE','wks_table_name':'','wks_side':'B','wks_rectype':'" + dataB[0].rule_rectype_id + "'}]}";
    // const step_additional_argv = "{'exec_mode':'detalle','exec_log_enable':'SI','exec_table_name_timestamp':'SI'}";
    // objBody.sql_id = 'GWA_CRUD';
    // objBody.params = '[]';
    // objBody.sql_ve = '{"GC_CMD": "UPDATE", "GC_TABLE": "mbr_step_definition", "GC_JSF": "{\\\\"step_rule_id\\\\": \\\\"' + step_rule_id + '\\\\", \\\\"step_order\\\\": \\\\"' + step_order + '\\\\", \\\\"step_name\\\\": \\\\"' + step_name + '\\\\", \\\\"step_type\\\\": \\\\"' + step_type + '\\\\", \\\\"step_cmd\\\\": \\\\"' + step_cmd + '\\\\", \\\\"step_common_argv\\\\": \\\\"' + step_common_argv + '\\\\", \\\\"step_additional_argv\\\\": \\\\"' + step_additional_argv + '\\\\", \\\\"step_text\\\\": \\\\"' + step_text + '\\\\", \\\\"step_active\\\\": \\\\"' + step_active + '\\\\", \\\\"step_run_mode\\\\": \\\\"' + step_run_mode + '\\\\", \\\\"step_alert_at_finish\\\\": \\\\"' + step_alert_at_finish + '\\\\", \\\\"step_stop_limit_faults\\\\": \\\\"' + step_stop_limit_faults + '\\\\", \\\\"step_progress_number_report\\\\": \\\\"' + step_progress_number_report + '\\\\"}", "GC_JSK": "{\\\\"process_id\\\\": \\\\"' + process_id + '\\\\", \\\\"process_dom_id\\\\": \\\\"' + process_dom_id + '\\\\", \\\\"step_id\\\\": \\\\"' + step_id + '\\\\"}"}';
    // jsonBody = JSON.stringify(objBody);
    // myUrl = url_api_GWA;
    objBody = {};
    objBody.process_id = process_id;
    objBody.process_dom_id = process_dom_id;
    objBody.step_id = step_id;
    objBody.step_rule_id = step_rule_id;
    objBody.step_order = step_order;
    objBody.step_name = step_name;
    objBody.step_type = step_type;
    objBody.step_cmd = step_cmd;
    objBody.step_common_argv = '{"dom_id":"' + process_dom_id + '","rule_id":"' + step_rule_id + '","wks_id":"","process_id":"' + process_id + '","step_id":"' + step_id + '","elements":[{"wks_element":"' + dataA[0].rule_dse_id + '","wks_type":"DATA_SOURCE","wks_table_name":"","wks_side":"A","wks_rectype":"' + dataA[0].rule_rectype_id + '"},{"wks_element":"' + dataB[0].rule_dse_id + '","wks_type":"DATA_SOURCE","wks_table_name":"","wks_side":"B","wks_rectype":"' + dataB[0].rule_rectype_id + '"}]}';
    objBody.step_additional_argv = '{"exec_mode":"DETALLE","exec_log_enable":"SI","exec_table_name_timestamp":"SI"}';
    objBody.step_text = step_text;
    objBody.step_active = step_active;
    objBody.step_run_mode = step_run_mode;
    objBody.step_alert_at_finish = step_alert_at_finish;
    objBody.step_stop_limit_faults = step_stop_limit_faults;
    objBody.step_progress_number_report = step_progress_number_report;
    jsonBody = JSON.stringify(objBody);
    myUrl = url_api_update;
    response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error actualizando el registro');
    }
    const updatedData = await response.json();
    return updatedData;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

// Ingresar un registro
const insert_record = async (id) => {
  try {
    const { process_id, process_dom_id, step_id, step_rule_id, step_order, step_name, step_type, step_cmd, step_text, step_active, step_run_mode, step_alert_at_finish, step_stop_limit_faults, step_progress_number_report } = id;

    let objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "br_rule_fields", "GC_JSF": "{\\\\"rule_dom_id\\\\": \\\\"\\\\", \\\\"rule_dse_id\\\\": \\\\"\\\\", \\\\"rule_cruce_id\\\\": \\\\"\\\\", \\\\"rule_side\\\\": \\\\"\\\\", \\\\"rule_field_name\\\\": \\\\"\\\\", \\\\"rule_rectype_id\\\\": \\\\"\\\\", \\\\"rule_factor\\\\": \\\\"\\\\", \\\\"rule_operator\\\\": \\\\"\\\\", \\\\"rule_field_order\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"rule_dom_id\\\\": \\\\"' + process_dom_id + '\\\\", \\\\"rule_cruce_id\\\\": \\\\"' + step_rule_id + '\\\\", \\\\"rule_side\\\\": \\\\"A\\\\"}"}';
    let jsonBody = JSON.stringify(objBody);
    let myUrl = url_api_GWA;
    let response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const dataA = await response.json();

    objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "br_rule_fields", "GC_JSF": "{\\\\"rule_dom_id\\\\": \\\\"\\\\", \\\\"rule_dse_id\\\\": \\\\"\\\\", \\\\"rule_cruce_id\\\\": \\\\"\\\\", \\\\"rule_side\\\\": \\\\"\\\\", \\\\"rule_field_name\\\\": \\\\"\\\\", \\\\"rule_rectype_id\\\\": \\\\"\\\\", \\\\"rule_factor\\\\": \\\\"\\\\", \\\\"rule_operator\\\\": \\\\"\\\\", \\\\"rule_field_order\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"rule_dom_id\\\\": \\\\"' + process_dom_id + '\\\\", \\\\"rule_cruce_id\\\\": \\\\"' + step_rule_id + '\\\\", \\\\"rule_side\\\\": \\\\"B\\\\"}"}';
    jsonBody = JSON.stringify(objBody);
    myUrl = url_api_GWA;
    response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const dataB = await response.json();

    // objBody = {};
    // const step_common_argv = '{ "dom_id":"' + process_dom_id + '","rule_id":"' + step_rule_id +'","wks_id":"","elements": [ { "wks_element":"' + dataA[0].rule_dse_id + '", "wks_type":"DATA_SOURCE", "wks_table_name":"", "wks_side":"A", "wks_rectype":"' + dataA[0].rule_rectype_id + '" }, {"wks_element":"' + dataB[0].rule_dse_id + '","wks_type":"DATA_SOURCE","wks_table_name":"","wks_side":"B","wks_rectype":"' + dataB[0].rule_rectype_id + '"}]}';
    // const step_additional_argv = '{"exec_mode":"detalle","exec_log_enable":"SI","exec_table_name_timestamp":"SI"}';
    // objBody.sql_id = 'GWA_CRUD';
    // objBody.params = '[]';
    // objBody.sql_ve = '{"GC_CMD": "INSERT", "GC_TABLE": "mbr_step_definition", "GC_JSF": "{\\\\"process_id\\\\": \\\\"' + process_id + '\\\\", \\\\"process_dom_id\\\\": \\\\"' + process_dom_id + '\\\\", \\\\"step_id\\\\": \\\\"' + step_id + '\\\\", \\\\"step_rule_id\\\\": \\\\"' + step_rule_id + '\\\\", \\\\"step_order\\\\": \\\\"' + step_order + '\\\\", \\\\"step_name\\\\": \\\\"' + step_name + '\\\\", \\\\"step_type\\\\": \\\\"' + step_type + '\\\\", \\\\"step_cmd\\\\": \\\\"' + step_cmd + '\\\\", \\\\"step_common_argv\\\\": \\\\"' + step_common_argv + '\\\\", \\\\"step_additional_argv\\\\": \\\\"' + step_additional_argv + '\\\\", \\\\"step_text\\\\": \\\\"' + step_text + '\\\\", \\\\"step_active\\\\": \\\\"' + step_active + '\\\\", \\\\"step_run_mode\\\\": \\\\"' + step_run_mode + '\\\\", \\\\"step_alert_at_finish\\\\": \\\\"' + step_alert_at_finish + '\\\\", \\\\"step_stop_limit_faults\\\\": \\\\"' + step_stop_limit_faults + '\\\\", \\\\"step_progress_number_report\\\\": \\\\"' + step_progress_number_report + '\\\\"}", "GC_JSK": "{}"}';
    // jsonBody = JSON.stringify(objBody);
    // myUrl = url_api_GWA;
    objBody = {};
    objBody.process_id = process_id;
    objBody.process_dom_id = process_dom_id;
    objBody.step_id = step_id;
    objBody.step_rule_id = step_rule_id;
    objBody.step_order = step_order;
    objBody.step_name = step_name;
    objBody.step_type = step_type;
    objBody.step_cmd = step_cmd;
    objBody.step_common_argv = '{"dom_id":"' + process_dom_id + '","rule_id":"' + step_rule_id + '","wks_id":"","process_id":"' + process_id + '","step_id":"' + step_id + '","elements":[{"wks_element":"' + dataA[0].rule_dse_id + '","wks_type":"DATA_SOURCE","wks_table_name":"","wks_side":"A","wks_rectype":"' + dataA[0].rule_rectype_id + '"},{"wks_element":"' + dataB[0].rule_dse_id + '","wks_type":"DATA_SOURCE","wks_table_name":"","wks_side":"B","wks_rectype":"' + dataB[0].rule_rectype_id + '"}]}';
    objBody.step_additional_argv = '{"exec_mode":"DETALLE","exec_log_enable":"SI","exec_table_name_timestamp":"SI"}';
    objBody.step_text = step_text;
    objBody.step_active = step_active;
    objBody.step_run_mode = step_run_mode;
    objBody.step_alert_at_finish = step_alert_at_finish;
    objBody.step_stop_limit_faults = step_stop_limit_faults;
    objBody.step_progress_number_report = step_progress_number_report;
    jsonBody = JSON.stringify(objBody);
    myUrl = url_api_insert;
    response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error insertando el registro');
    }
    const updatedData = await response.json();
    return updatedData;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

// Obtener los registros para los dropdown
const get_dropdown_data = async (field, dom_id) => {
  try {
    if (field === 'step_rule_id') {
      const objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      // objBody.sql_ve = '{"GC_CMD": "RETRIEVE_ALL", "GC_TABLE": "br_rule_routines", "GC_JSF": "{\\\\"rule_routine_id\\\\": \\\\"\\\\", \\\\"rule_routine_name\\\\": \\\\"\\\\", \\\\"rule_routine_nemo\\\\": \\\\"\\\\", \\\\"rule_routine_type\\\\": \\\\"\\\\", \\\\"rule_routine_call\\\\": \\\\"\\\\", \\\\"rule_routine_common_argv\\\\": \\\\"\\\\", \\\\"rule_routine_additional_argv\\\\": \\\\"\\\\", \\\\"rule_routine_file_nok\\\\": \\\\"\\\\", \\\\"rule_routine_file_max_nok\\\\": \\\\"\\\\"}", "GC_JSK": "{}"}';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "br_rule_master", "GC_JSF": "{\\\\"rule_dom_id\\\\": \\\\"\\\\", \\\\"rule_cruce_id\\\\": \\\\"\\\\", \\\\"rule_cruce_name\\\\": \\\\"\\\\", \\\\"rule_cruce_file_nok\\\\": \\\\"\\\\", \\\\"rule_cruce_file_max_nok\\\\": \\\\"\\\\", \\\\"rule_routine_id\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"rule_dom_id\\\\": \\\\"' + dom_id + '\\\\"}"}';
      const jsonBody = JSON.stringify(objBody);
      const myUrl = url_api_GWA;
      const response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      for (let i = 0; i < data.length; i++) {
        data[i] = { ...data[i], step_rule_id: data[i].rule_cruce_name, step_rule_idvalue: data[i].rule_cruce_id };
      }
      // const result = data.map((item) => { return ({ ...item, step_rule_id: item.rule_cruce_id }) });
      return data;
    }
    else if (field === 'step_type') {
      const objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      // objBody.sql_ve = '{"GC_CMD": "RETRIEVE_ALL", "GC_TABLE": "br_rule_routines", "GC_JSF": "{\\\\"rule_routine_id\\\\": \\\\"\\\\", \\\\"rule_routine_name\\\\": \\\\"\\\\", \\\\"rule_routine_nemo\\\\": \\\\"\\\\", \\\\"rule_routine_type\\\\": \\\\"\\\\", \\\\"rule_routine_call\\\\": \\\\"\\\\", \\\\"rule_routine_common_argv\\\\": \\\\"\\\\", \\\\"rule_routine_additional_argv\\\\": \\\\"\\\\", \\\\"rule_routine_file_nok\\\\": \\\\"\\\\", \\\\"rule_routine_file_max_nok\\\\": \\\\"\\\\"}", "GC_JSK": "{}"}';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE_ALL", "GC_TABLE": "mbr_sys_step_type", "GC_JSF": "{\\\\"step_type\\\\": \\\\"\\\\", \\\\"nemo_step_type\\\\": \\\\"\\\\"}", "GC_JSK": "{}"}';
      const jsonBody = JSON.stringify(objBody);
      const myUrl = url_api_GWA;
      const response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      for (let i = 0; i < data.length; i++) {
        data[i] = { ...data[i], step_type: data[i].nemo_step_type, step_typevalue: data[i].step_type };
      }
      return data;
    }
    else if (field === 'step_cmd') {
      const objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      // objBody.sql_ve = '{"GC_CMD": "RETRIEVE_ALL", "GC_TABLE": "br_rule_routines", "GC_JSF": "{\\\\"rule_routine_id\\\\": \\\\"\\\\", \\\\"rule_routine_name\\\\": \\\\"\\\\", \\\\"rule_routine_nemo\\\\": \\\\"\\\\", \\\\"rule_routine_type\\\\": \\\\"\\\\", \\\\"rule_routine_call\\\\": \\\\"\\\\", \\\\"rule_routine_common_argv\\\\": \\\\"\\\\", \\\\"rule_routine_additional_argv\\\\": \\\\"\\\\", \\\\"rule_routine_file_nok\\\\": \\\\"\\\\", \\\\"rule_routine_file_max_nok\\\\": \\\\"\\\\"}", "GC_JSK": "{}"}';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE_ALL", "GC_TABLE": "mbr_sys_step_cmd", "GC_JSF": "{\\\\"step_cmd\\\\": \\\\"\\\\", \\\\"nemo_step_cmd\\\\": \\\\"\\\\"}", "GC_JSK": "{}"}';
      const jsonBody = JSON.stringify(objBody);
      const myUrl = url_api_GWA;
      const response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      for (let i = 0; i < data.length; i++) {
        data[i] = { ...data[i], step_cmd: data[i].nemo_step_cmd, step_cmdvalue: data[i].step_cmd };
      }
      return data;
    }
    else if (field === 'step_run_mode') {
      const objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      // objBody.sql_ve = '{"GC_CMD": "RETRIEVE_ALL", "GC_TABLE": "br_rule_routines", "GC_JSF": "{\\\\"rule_routine_id\\\\": \\\\"\\\\", \\\\"rule_routine_name\\\\": \\\\"\\\\", \\\\"rule_routine_nemo\\\\": \\\\"\\\\", \\\\"rule_routine_type\\\\": \\\\"\\\\", \\\\"rule_routine_call\\\\": \\\\"\\\\", \\\\"rule_routine_common_argv\\\\": \\\\"\\\\", \\\\"rule_routine_additional_argv\\\\": \\\\"\\\\", \\\\"rule_routine_file_nok\\\\": \\\\"\\\\", \\\\"rule_routine_file_max_nok\\\\": \\\\"\\\\"}", "GC_JSK": "{}"}';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE_ALL", "GC_TABLE": "mbr_sys_step_run_mode", "GC_JSF": "{\\\\"step_run_mode\\\\": \\\\"\\\\", \\\\"nemo_step_run_mode\\\\": \\\\"\\\\"}", "GC_JSK": "{}"}';
      const jsonBody = JSON.stringify(objBody);
      const myUrl = url_api_GWA;
      const response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      for (let i = 0; i < data.length; i++) {
        data[i] = { ...data[i], step_run_mode: data[i].nemo_step_run_mode, step_run_modevalue: data[i].step_run_mode };
      }
      return data;
    }
    else if (field === 'step_active') {
      const objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE_ALL", "GC_TABLE": "mbr_sys_yesno", "GC_JSF": "{\\\\"yesno_code\\\\": \\\\"\\\\", \\\\"yesno_nemo\\\\": \\\\"\\\\"}", "GC_JSK": "{}"}';
      const jsonBody = JSON.stringify(objBody);
      const myUrl = url_api_GWA;
      const response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      for (let i = 0; i < data.length; i++) {
        data[i] = { ...data[i], step_active: data[i].yesno_nemo, step_activevalue: data[i].yesno_nemo };
      }
      return data;
    }
    else if (field === 'step_alert_at_finish') {
      const objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE_ALL", "GC_TABLE": "mbr_sys_yesno", "GC_JSF": "{\\\\"yesno_code\\\\": \\\\"\\\\", \\\\"yesno_nemo\\\\": \\\\"\\\\"}", "GC_JSK": "{}"}';
      const jsonBody = JSON.stringify(objBody);
      const myUrl = url_api_GWA;
      const response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      for (let i = 0; i < data.length; i++) {
        data[i] = { ...data[i], step_alert_at_finish: data[i].yesno_nemo, step_alert_at_finishvalue: data[i].yesno_code };
      }
      return data;
    }
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

const get_dropdown_data_value = async (field, value, dom_id) => {
  try {
    if (field === 'step_rule_id') {
      const objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      // objBody.sql_ve = '{"GC_CMD": "RETRIEVE_ALL", "GC_TABLE": "br_rule_routines", "GC_JSF": "{\\\\"rule_routine_id\\\\": \\\\"\\\\", \\\\"rule_routine_name\\\\": \\\\"\\\\", \\\\"rule_routine_nemo\\\\": \\\\"\\\\", \\\\"rule_routine_type\\\\": \\\\"\\\\", \\\\"rule_routine_call\\\\": \\\\"\\\\", \\\\"rule_routine_common_argv\\\\": \\\\"\\\\", \\\\"rule_routine_additional_argv\\\\": \\\\"\\\\", \\\\"rule_routine_file_nok\\\\": \\\\"\\\\", \\\\"rule_routine_file_max_nok\\\\": \\\\"\\\\"}", "GC_JSK": "{}"}';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "br_rule_master", "GC_JSF": "{\\\\"rule_dom_id\\\\": \\\\"\\\\", \\\\"rule_cruce_id\\\\": \\\\"\\\\", \\\\"rule_cruce_name\\\\": \\\\"\\\\", \\\\"rule_cruce_file_nok\\\\": \\\\"\\\\", \\\\"rule_cruce_file_max_nok\\\\": \\\\"\\\\", \\\\"rule_routine_id\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"rule_dom_id\\\\": \\\\"' + dom_id + '\\\\", \\\\"rule_cruce_id\\\\": \\\\"' + value + '\\\\"}"}';
      const jsonBody = JSON.stringify(objBody);
      const myUrl = url_api_GWA;
      const response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      for (let i = 0; i < data.length; i++) {
        data[i] = { ...data[i], step_rule_id: data[i].rule_cruce_name, step_rule_idvalue: data[i].rule_cruce_id };
      }
      // const result = data.map((item) => { return ({ ...item, step_rule_id: item.rule_cruce_id }) });
      return data;
    }
    else if (field === 'step_type') {
      const objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      // objBody.sql_ve = '{"GC_CMD": "RETRIEVE_ALL", "GC_TABLE": "br_rule_routines", "GC_JSF": "{\\\\"rule_routine_id\\\\": \\\\"\\\\", \\\\"rule_routine_name\\\\": \\\\"\\\\", \\\\"rule_routine_nemo\\\\": \\\\"\\\\", \\\\"rule_routine_type\\\\": \\\\"\\\\", \\\\"rule_routine_call\\\\": \\\\"\\\\", \\\\"rule_routine_common_argv\\\\": \\\\"\\\\", \\\\"rule_routine_additional_argv\\\\": \\\\"\\\\", \\\\"rule_routine_file_nok\\\\": \\\\"\\\\", \\\\"rule_routine_file_max_nok\\\\": \\\\"\\\\"}", "GC_JSK": "{}"}';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "mbr_sys_step_type", "GC_JSF": "{\\\\"step_type\\\\": \\\\"\\\\", \\\\"nemo_step_type\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"step_type\\\\": \\\\"' + value + '\\\\"}"}';
      const jsonBody = JSON.stringify(objBody);
      const myUrl = url_api_GWA;
      const response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      for (let i = 0; i < data.length; i++) {
        data[i] = { ...data[i], step_type: data[i].nemo_step_type, step_typevalue: data[i].step_type };
      }
      return data;
    }
    else if (field === 'step_cmd') {
      const objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      // objBody.sql_ve = '{"GC_CMD": "RETRIEVE_ALL", "GC_TABLE": "br_rule_routines", "GC_JSF": "{\\\\"rule_routine_id\\\\": \\\\"\\\\", \\\\"rule_routine_name\\\\": \\\\"\\\\", \\\\"rule_routine_nemo\\\\": \\\\"\\\\", \\\\"rule_routine_type\\\\": \\\\"\\\\", \\\\"rule_routine_call\\\\": \\\\"\\\\", \\\\"rule_routine_common_argv\\\\": \\\\"\\\\", \\\\"rule_routine_additional_argv\\\\": \\\\"\\\\", \\\\"rule_routine_file_nok\\\\": \\\\"\\\\", \\\\"rule_routine_file_max_nok\\\\": \\\\"\\\\"}", "GC_JSK": "{}"}';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "mbr_sys_step_cmd", "GC_JSF": "{\\\\"step_cmd\\\\": \\\\"\\\\", \\\\"nemo_step_cmd\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"step_cmd\\\\": \\\\"' + value + '\\\\"}"}';
      const jsonBody = JSON.stringify(objBody);
      const myUrl = url_api_GWA;
      const response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      for (let i = 0; i < data.length; i++) {
        data[i] = { ...data[i], step_cmd: data[i].nemo_step_cmd, step_cmdvalue: data[i].step_cmd };
      }
      return data;
    }
    else if (field === 'step_run_mode') {
      const objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      // objBody.sql_ve = '{"GC_CMD": "RETRIEVE_ALL", "GC_TABLE": "br_rule_routines", "GC_JSF": "{\\\\"rule_routine_id\\\\": \\\\"\\\\", \\\\"rule_routine_name\\\\": \\\\"\\\\", \\\\"rule_routine_nemo\\\\": \\\\"\\\\", \\\\"rule_routine_type\\\\": \\\\"\\\\", \\\\"rule_routine_call\\\\": \\\\"\\\\", \\\\"rule_routine_common_argv\\\\": \\\\"\\\\", \\\\"rule_routine_additional_argv\\\\": \\\\"\\\\", \\\\"rule_routine_file_nok\\\\": \\\\"\\\\", \\\\"rule_routine_file_max_nok\\\\": \\\\"\\\\"}", "GC_JSK": "{}"}';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "mbr_sys_step_run_mode", "GC_JSF": "{\\\\"step_run_mode\\\\": \\\\"\\\\", \\\\"nemo_step_run_mode\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"step_run_mode\\\\": \\\\"' + value + '\\\\"}"}';
      const jsonBody = JSON.stringify(objBody);
      const myUrl = url_api_GWA;
      const response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      for (let i = 0; i < data.length; i++) {
        data[i] = { ...data[i], step_run_mode: data[i].nemo_step_run_mode, step_run_modevalue: data[i].step_run_mode };
      }
      return data;
    }
    else if (field === 'step_active') {
      const objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "mbr_sys_yesno", "GC_JSF": "{\\\\"yesno_code\\\\": \\\\"\\\\", \\\\"yesno_nemo\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"yesno_code\\\\": \\\\"' + value + '\\\\"}"}';
      const jsonBody = JSON.stringify(objBody);
      const myUrl = url_api_GWA;
      const response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      for (let i = 0; i < data.length; i++) {
        data[i] = { ...data[i], step_active: data[i].yesno_nemo, step_activevalue: data[i].yesno_nemo };
      }
      return data;
    }
    else if (field === 'step_alert_at_finish') {
      const objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "mbr_sys_yesno", "GC_JSF": "{\\\\"yesno_code\\\\": \\\\"\\\\", \\\\"yesno_nemo\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"yesno_code\\\\": \\\\"' + value + '\\\\"}"}';
      const jsonBody = JSON.stringify(objBody);
      const myUrl = url_api_GWA;
      const response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      for (let i = 0; i < data.length; i++) {
        data[i] = { ...data[i], step_alert_at_finish: data[i].yesno_nemo, step_alert_at_finishvalue: data[i].yesno_code };
      }
      return data;
    }
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

export { get_all_records, delete_record, update_record, insert_record, get_dropdown_data, get_dropdown_data_value };