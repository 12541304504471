import { get_all_records, delete_record, update_record, insert_record } from './connect';
/**
 * Textos del sistema utilizados en varios componentes
 */
export const sys_texts = {
  browse_nuevo: "Nuevo",
  form_guardar: "Guardar",
  form_cerrar: "Cerrar",
  search_placeholder: "Buscar",
  // Otros textos del sistema
};

/**
 * Textos específicos del CRUD para la entidad dominios
 */
export const crud_texts = {
  browse_titulo: "WorkSpaces",
  form_titulo: "WorkSpace",
  modal_message: "¿Está seguro que desea eliminar este workspace?",
  modal_title: "Confirmación",
  modal_positive: "Eliminar",
  modal_negative: "Cancelar",
  // Otros textos específicos del CRUD
};

/**
 * Campos para el componente de navegación (browse)
 */
export const browse_json_fields = [
  { field: 'dom_id', title: 'ID de Dominio', key: true, type: 'text', len: 20 },
  { field: 'wks_id', title: 'ID de Workspace', key: true, type: 'text', len: 20 },
  { field: 'wks_element', title: 'Elemento', key: true, type: 'text', len: 20 },
  // Otros campos
];

/**
 * Campos para el formulario (form)
 */
export const form_json_fields = [
  { field: 'dom_id', title: 'ID de dominio', key: true, type: 'text', len: 20 },
  { field: 'wks_id', title: 'ID de workspace', key: true, type: 'text', len: 20 },
  { field: 'wks_element', title: 'Elemento', key: true, type: 'text', len: 20 },
  { field: 'wks_date', title: 'Fecha', type: 'date', len: 20 },
  { field: 'wks_sys_status', title: 'Estado', type: 'text', len: 20 },
  // Otros campos específicos para el formulario
];

/**
 * Rutas de navegación
 */
export const routes = {
  edit: '/operation/workspaces/edit',
  new: '/operation/workspaces/new',
  list: '/operation/workspaces'
};

/**
 * Variables del entorno
 */
export const set_vars = () => {
  return {
    set_browse_carga_ds: true,
  };
};

// Registro seleccionado
let selectedRecord = null;

export const setSelectedRecord = (record) => {
  selectedRecord = record;
};

export const getSelectedRecord = () => {
  return selectedRecord;
};

export { get_all_records, delete_record, update_record, insert_record };