import axios from 'axios';

const url_api_GWA = 'https://www.reclamospae.cl:5000/api/GWA';
const url_api_download = 'https://www.reclamospae.cl:5000/process_exec/bajarresultado';

// Obtener todos los registros
const get_all_records = async (id) => {
  try {
    const { process_id, process_dom_id, process_exec_spacework } = id;
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "mbr_step_exec", "GC_JSF": "{\\\\"process_id\\\\": \\\\"\\\\", \\\\"process_dom_id\\\\": \\\\"\\\\", \\\\"step_id\\\\": \\\\"\\\\", \\\\"process_exec_spacework\\\\": \\\\"\\\\", \\\\"step_rule_id\\\\": \\\\"\\\\", \\\\"step_exec_order\\\\": \\\\"\\\\", \\\\"step_exec_name\\\\": \\\\"\\\\", \\\\"step_exec_type\\\\": \\\\"\\\\", \\\\"step_exec_cmd\\\\": \\\\"\\\\", \\\\"step_exec_common_argv\\\\": \\\\"\\\\", \\\\"step_exec_additional_argv\\\\": \\\\"\\\\", \\\\"step_exec_active\\\\": \\\\"\\\\", \\\\"step_exec_run_mode\\\\": \\\\"\\\\", \\\\"step_exec_status\\\\": \\\\"\\\\", \\\\"step_exec_init_datetime\\\\": \\\\"\\\\", \\\\"step_exec_finish_datetime\\\\": \\\\"\\\\", \\\\"step_exec_alert_at_finish\\\\": \\\\"\\\\", \\\\"step_exec_stop_number_faults\\\\": \\\\"\\\\", \\\\"step_exec_progress\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"process_id\\\\": \\\\"' + process_id + '\\\\", \\\\"process_dom_id\\\\": \\\\"' + process_dom_id + '\\\\", \\\\"process_exec_spacework\\\\": \\\\"' + process_exec_spacework + '\\\\", \\\\"step_exec_status\\\\": \\\\"STS_COMPLETED\\\\"}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

// Eliminar un registro
const delete_record = async (id) => {
  return null;
};

// Actualizar un registro
const update_record = async (data) => {
  return null;
};

// Ingresar un registro
const insert_record = async (data) => {
  return null;
};

const delay = ms => new Promise(res => setTimeout(res, ms));
// Descargar un resultado
const download_file = async (id, updateDownloading, updateProgressDownload) => {
  updateProgressDownload(0);
  updateDownloading(true);
  try {
    const { process_id, process_dom_id, process_exec_spacework, step_id } = id;
    const response = await axios.post(url_api_download, {
      process_id: process_id,
      process_dom_id: process_dom_id,
      process_exec_spacework: process_exec_spacework,
      step_id: step_id,
    },
      {
        headers: {
          "Content-Type": "application/JSON"
        },
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          updateProgressDownload(percentCompleted);
        },
      });

    await delay(500);
    updateProgressDownload(100);
    const userFileName = 'Resultados_' + process_id + '_' + process_dom_id + '_' + process_exec_spacework + '_' + step_id + '.xlsx';
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = userFileName || 'downloadedFile.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    return null;
  } catch (error) {
    console.error('Error downloading file:', error);
    return null;
  }
};

export { get_all_records, delete_record, update_record, insert_record, download_file };