const url_api_GWA = 'https://www.reclamospae.cl:5000/api/GWA';

// Obtener todos los registros
const get_all_records = async (id) => {
  try {
    const { dom_id } = id;
    let objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "br_rule_master", "GC_JSF": "{\\\\"rule_dom_id\\\\": \\\\"\\\\", \\\\"rule_cruce_id\\\\": \\\\"\\\\", \\\\"rule_cruce_name\\\\": \\\\"\\\\", \\\\"rule_cruce_file_nok\\\\": \\\\"\\\\", \\\\"rule_cruce_file_max_nok\\\\": \\\\"\\\\", \\\\"rule_routine_id\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"rule_dom_id\\\\": \\\\"' + dom_id + '\\\\"}"}';
    let jsonBody = JSON.stringify(objBody);
    let myUrl = url_api_GWA;
    let response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    let data = await response.json();

    for (let i = 0; i < data.length; i++) {
      objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "br_rule_routines", "GC_JSF": "{\\\\"rule_routine_id\\\\": \\\\"\\\\", \\\\"rule_routine_name\\\\": \\\\"\\\\", \\\\"rule_routine_nemo\\\\": \\\\"\\\\", \\\\"rule_routine_type\\\\": \\\\"\\\\", \\\\"rule_routine_call\\\\": \\\\"\\\\", \\\\"rule_routine_common_argv\\\\": \\\\"\\\\", \\\\"rule_routine_additional_argv\\\\": \\\\"\\\\", \\\\"rule_routine_file_nok\\\\": \\\\"\\\\", \\\\"rule_routine_file_max_nok\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"rule_routine_id\\\\": \\\\"' + data[i].rule_routine_id + '\\\\"}"}';
      jsonBody = JSON.stringify(objBody);
      myUrl = url_api_GWA;
      response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const dataRule = await response.json();
      if (dataRule.length > 0)
        data[i] = { ...data[i], rule_routine_nemo: dataRule[0].rule_routine_nemo };
    }

    return data;
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

// Eliminar un registro
const delete_record = async (id) => {
  try {
    const { rule_dom_id, rule_cruce_id } = JSON.parse(id);
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "DELETE", "GC_TABLE": "br_rule_master", "GC_JSF": "{}", "GC_JSK": "{\\\\"rule_dom_id\\\\": \\\\"' + rule_dom_id + '\\\\", \\\\"rule_cruce_id\\\\": \\\\"' + rule_cruce_id + '\\\\"}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error eliminando el registro');
    }
    return id;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

// Actualizar un registro
const update_record = async (data) => {
  try {
    const { rule_dom_id, rule_cruce_id, rule_cruce_name, rule_cruce_file_nok, rule_cruce_file_max_nok, rule_routine_id } = data;
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "UPDATE", "GC_TABLE": "br_rule_master", "GC_JSF": "{\\\\"rule_cruce_id\\\\": \\\\"' + rule_cruce_id + '\\\\", \\\\"rule_cruce_name\\\\": \\\\"' + rule_cruce_name + '\\\\", \\\\"rule_cruce_file_nok\\\\": \\\\"' + rule_cruce_file_nok + '\\\\", \\\\"rule_cruce_file_max_nok\\\\": \\\\"' + rule_cruce_file_max_nok + '\\\\", \\\\"rule_routine_id\\\\": \\\\"' + rule_routine_id + '\\\\"}", "GC_JSK": "{\\\\"rule_dom_id\\\\": \\\\"' + rule_dom_id + '\\\\"}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error actualizando el registro');
    }
    const updatedData = await response.json();
    return updatedData;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

// Ingresar un registro
const insert_record = async (data) => {
  try {
    const { rule_dom_id, rule_cruce_id, rule_cruce_name, rule_cruce_file_nok, rule_cruce_file_max_nok, rule_routine_id } = data;
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "INSERT", "GC_TABLE": "br_rule_master", "GC_JSF": "{\\\\"rule_dom_id\\\\": \\\\"' + rule_dom_id + '\\\\", \\\\"rule_cruce_id\\\\": \\\\"' + rule_cruce_id + '\\\\", \\\\"rule_cruce_name\\\\": \\\\"' + rule_cruce_name + '\\\\", \\\\"rule_cruce_file_nok\\\\": \\\\"' + rule_cruce_file_nok + '\\\\", \\\\"rule_cruce_file_max_nok\\\\": \\\\"' + rule_cruce_file_max_nok + '\\\\", \\\\"rule_routine_id\\\\": \\\\"' + rule_routine_id + '\\\\"}", "GC_JSK": "{}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error actualizando el registro');
    }
    const updatedData = await response.json();
    return updatedData;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

// Obtener los registros para los dropdown
const get_dropdown_data = async (field) => {
  try {
    if (field === 'rule_routine_id') {
      const objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE_ALL", "GC_TABLE": "br_rule_routines", "GC_JSF": "{\\\\"rule_routine_id\\\\": \\\\"\\\\", \\\\"rule_routine_name\\\\": \\\\"\\\\", \\\\"rule_routine_nemo\\\\": \\\\"\\\\", \\\\"rule_routine_type\\\\": \\\\"\\\\", \\\\"rule_routine_call\\\\": \\\\"\\\\", \\\\"rule_routine_common_argv\\\\": \\\\"\\\\", \\\\"rule_routine_additional_argv\\\\": \\\\"\\\\", \\\\"rule_routine_file_nok\\\\": \\\\"\\\\", \\\\"rule_routine_file_max_nok\\\\": \\\\"\\\\"}", "GC_JSK": "{}"}';
      const jsonBody = JSON.stringify(objBody);
      const myUrl = url_api_GWA;
      const response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      for (let i = 0; i < data.length; i++) {
        data[i] = { ...data[i], rule_routine_id: data[i].rule_routine_nemo, rule_routine_idvalue: data[i].rule_routine_id };
      }
      return data;
    }
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

const get_dropdown_data_value = async (field, value) => {
  try {
    if (field === 'rule_routine_id') {
      const objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "br_rule_routines", "GC_JSF": "{\\\\"rule_routine_id\\\\": \\\\"\\\\", \\\\"rule_routine_name\\\\": \\\\"\\\\", \\\\"rule_routine_nemo\\\\": \\\\"\\\\", \\\\"rule_routine_type\\\\": \\\\"\\\\", \\\\"rule_routine_call\\\\": \\\\"\\\\", \\\\"rule_routine_common_argv\\\\": \\\\"\\\\", \\\\"rule_routine_additional_argv\\\\": \\\\"\\\\", \\\\"rule_routine_file_nok\\\\": \\\\"\\\\", \\\\"rule_routine_file_max_nok\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"rule_routine_id\\\\": \\\\"' + value + '\\\\"}"}';
      const jsonBody = JSON.stringify(objBody);
      const myUrl = url_api_GWA;
      const response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      for (let i = 0; i < data.length; i++) {
        data[i] = { ...data[i], rule_routine_id: data[i].rule_routine_nemo, rule_routine_idvalue: data[i].rule_routine_id };
      }
      return data;
    }
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

export { get_all_records, delete_record, update_record, insert_record, get_dropdown_data, get_dropdown_data_value };