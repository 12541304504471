import axios from 'axios';

const url_api_GWA = 'https://www.reclamospae.cl:5000/api/GWA';
const url_api_upload = 'https://www.reclamospae.cl:5000/api/files/upload';

// Obtener todos los registros
const get_all_records = async (id) => {
  try {
    const { dom_id, wks_id } = id;
    let objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "ds_element_id", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"\\\\", \\\\"dse_id\\\\": \\\\"\\\\", \\\\"dse_name\\\\": \\\\"\\\\", \\\\"dse_template_name\\\\": \\\\"\\\\", \\\\"dse_desc\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dom_id + '\\\\"}"}';
    let jsonBody = JSON.stringify(objBody);
    let myUrl = url_api_GWA;
    let response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const result = await response.json();

    for (let i = 0;i < result.length; i++) {
      objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "mbr_wks_ds", "GC_JSF": "{\\\\"dom_id\\\\": \\\\"\\\\", \\\\"wks_id\\\\": \\\\"\\\\", \\\\"wks_element\\\\": \\\\"\\\\", \\\\"wks_date\\\\": \\\\"\\\\", \\\\"wks_sys_status\\\\": \\\\"\\\\", \\\\"wks_sys_progress\\\\": \\\\"\\\\", \\\\"wks_sys_records\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"dom_id\\\\": \\\\"' + dom_id + '\\\\", \\\\"wks_id\\\\": \\\\"' + wks_id + '\\\\", \\\\"wks_element\\\\": \\\\"' + result[i].dse_id + '\\\\"}"}';
      jsonBody = JSON.stringify(objBody);
      myUrl = url_api_GWA;
      response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.length > 0) {
        result[i] = {...result[i], wks_sys_status: data[0].wks_sys_status, wks_sys_progress: data[0].wks_sys_progress, wks_sys_records: data[0].wks_sys_records }
      } else {
        result[i] = {...result[i], wks_sys_status: "", wks_sys_progress: "", wks_sys_records: "" }
      }
    }
    
    return result;
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

// Eliminar un registro
const delete_record = async (id) => {
  return null;
};

// Actualizar un registro
const update_record = async (data) => {
  return null;
};

const delay = ms => new Promise(res => setTimeout(res, ms));
// Ingresar un registro
const insert_record = async (formData, updateUploading, updateProgress) => {
  updateProgress(0);
  updateUploading(true);
  try {
    await axios.post(url_api_upload, formData, {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        updateProgress(percentCompleted);
      },
    });
    await delay(500);
    updateProgress(100);
    return null;
  } catch (error) {
    console.error('Error uploading file:', error);
    return null;
  }
};

export { get_all_records, delete_record, update_record, insert_record };