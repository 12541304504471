import React, { createContext, useContext, useState } from 'react';

// Crear un contexto para los registros
const RecordContext = createContext();

export const useConnectionContext = () => useContext(RecordContext);

export const ConnectionProvider = ({ children, get_all_records, delete_record, update_record, insert_record, get_dropdown_data, set_vars }) => {
  const [data, setData] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [progressDownload, setProgressDownload] = useState(0);
  const [downloading, setDownloading] = useState(false);

  const updateData = (newData) => setData(newData);
  const getSelectedRecord = () => selectedRecord;
  const updateProgress = (progress) => setProgress(progress);
  const updateUploading = (uploading) => setUploading(uploading);
  const updateProgressDownload = (progress) => setProgressDownload(progress);
  const updateDownloading = (downloading) => setDownloading(downloading);

  return (
    <RecordContext.Provider value={{
      data,
      updateData,
      selectedRecord,
      setSelectedRecord,
      get_all_records,
      delete_record,
      update_record,
      insert_record,
      get_dropdown_data,
      set_vars,
      getSelectedRecord,
      progress,
      updateProgress,
      uploading,
      updateUploading,
      progressDownload,
      updateProgressDownload,
      downloading,
      updateDownloading,
    }}>
      {children}
    </RecordContext.Provider>
  );
};
