const url_api_GWA = 'https://www.reclamospae.cl:5000/api/GWA';

// Obtener todos los registros
const get_all_records = async (id) => {
  try {
    const { dse_dom_id, dse_id } = JSON.parse(id);
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "ds_element_rectypes", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"\\\\", \\\\"dse_id\\\\": \\\\"\\\\", \\\\"dse_rule_table_name\\\\": \\\\"\\\\", \\\\"dse_rectype_id\\\\": \\\\"\\\\", \\\\"dse_rectype_text\\\\": \\\\"\\\\", \\\\"dse_rectype_name\\\\": \\\\"\\\\", \\\\"dse_table_name_sufix\\\\": \\\\"\\\\", \\\\"dse_order\\\\": \\\\"\\\\", \\\\"dse_parse\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\"}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

// Eliminar un registro
const delete_record = async (id) => {
  try {
    const { dse_dom_id, dse_id, dse_rectype_id } = JSON.parse(id);
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "DELETE", "GC_TABLE": "ds_element_rectypes", "GC_JSF": "{}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\", \\\\"dse_rectype_id\\\\": \\\\"' + dse_rectype_id + '\\\\"}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error eliminando el registro');
    }
    return id;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

// Actualizar un registro
const update_record = async (data) => {
  try {
    const { dse_dom_id, dse_id, dse_rectype_id, dse_rectype_name } = data;
    let dse_rectype_text = '';
    if (dse_rectype_id.slice(0, 1) === 'R')
      dse_rectype_text = '0' + dse_rectype_id.slice(1, 2);
    const dse_table_name_sufix = (dse_id + '_' + dse_rectype_id).toLowerCase();

    let objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "ds_element_rectypes", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"\\\\", \\\\"dse_id\\\\": \\\\"\\\\", \\\\"dse_rule_table_name\\\\": \\\\"\\\\", \\\\"dse_rectype_id\\\\": \\\\"\\\\", \\\\"dse_rectype_text\\\\": \\\\"\\\\", \\\\"dse_rectype_name\\\\": \\\\"\\\\", \\\\"dse_table_name_sufix\\\\": \\\\"\\\\", \\\\"dse_order\\\\": \\\\"\\\\", \\\\"dse_parse\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\", \\\\"dse_rectype_id\\\\": \\\\"' + dse_rectype_id + '\\\\"}"}';
    let jsonBody = JSON.stringify(objBody);
    let myUrl = url_api_GWA;
    let response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const result = await response.json();
    let dse_order = 0;
    let dse_parse = 0;
    if (result.length > 0)
    {
      dse_order = result[0].dse_order;
      dse_parse = result[0].dse_parse
    }

    objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "UPDATE", "GC_TABLE": "ds_element_rectypes", "GC_JSF": "{\\\\"dse_rule_table_name\\\\": \\\\"' + dse_id + '_' + dse_rectype_id + '\\\\", \\\\"dse_rectype_text\\\\": \\\\"' + dse_rectype_text + '\\\\", \\\\"dse_rectype_name\\\\": \\\\"' + dse_rectype_name + '\\\\", \\\\"dse_table_name_sufix\\\\": \\\\"' + dse_table_name_sufix + '\\\\", \\\\"dse_order\\\\": \\\\"' + dse_order + '\\\\", \\\\"dse_parse\\\\": \\\\"' + dse_parse + '\\\\"}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\", \\\\"dse_rectype_id\\\\": \\\\"' + dse_rectype_id + '\\\\"}"}';
    jsonBody = JSON.stringify(objBody);
    myUrl = url_api_GWA;
    response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error actualizando el registro');
    }
    const updatedData = await response.json();
    return updatedData;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

// Ingresar un registro
const insert_record = async (data) => {
  try {
    const { dse_dom_id, dse_id, dse_rectype_id, dse_rectype_name } = data;
    let dse_rectype_text = '';
    let dse_parse = 0;
    if (dse_rectype_id.slice(0, 1) === 'R') {
      dse_rectype_text = '0' + dse_rectype_id.slice(1, 2);
      dse_parse = 1;
    }
    const dse_table_name_sufix = (dse_id + '_' + dse_rectype_id).toLowerCase();

    let objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "ds_element_rectypes", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"\\\\", \\\\"dse_id\\\\": \\\\"\\\\", \\\\"dse_rule_table_name\\\\": \\\\"\\\\", \\\\"dse_rectype_id\\\\": \\\\"\\\\", \\\\"dse_rectype_text\\\\": \\\\"\\\\", \\\\"dse_rectype_name\\\\": \\\\"\\\\", \\\\"dse_table_name_sufix\\\\": \\\\"\\\\", \\\\"dse_order\\\\": \\\\"\\\\", \\\\"dse_parse\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\"}"}';
    let jsonBody = JSON.stringify(objBody);
    let myUrl = url_api_GWA;
    let response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const result = await response.json();
    let dse_order = 0;
    for (let i = 0; i < result.length; i++) {
      if (result[i].dse_order > dse_order)
        dse_order = result[i].dse_order;
    }
    dse_order++;

    objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "INSERT", "GC_TABLE": "ds_element_rectypes", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"' + dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dse_id + '\\\\", \\\\"dse_rule_table_name\\\\": \\\\"' + dse_id + '_' + dse_rectype_id + '\\\\", \\\\"dse_rectype_id\\\\": \\\\"' + dse_rectype_id + '\\\\", \\\\"dse_rectype_text\\\\": \\\\"' + dse_rectype_text + '\\\\", \\\\"dse_rectype_name\\\\": \\\\"' + dse_rectype_name + '\\\\", \\\\"dse_table_name_sufix\\\\": \\\\"' + dse_table_name_sufix + '\\\\", \\\\"dse_order\\\\": \\\\"' + dse_order + '\\\\", \\\\"dse_parse\\\\": \\\\"' + dse_parse + '\\\\"}", "GC_JSK": "{}"}';
    jsonBody = JSON.stringify(objBody);
    myUrl = url_api_GWA;
    response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error actualizando el registro');
    }
    const updatedData = await response.json();
    return updatedData;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

export { get_all_records, delete_record, update_record, insert_record };